.default-pagination {
  .swiper-pagination-bullet {
    color: transparent;
    border-color: #888888;
    border-width: 1px;
    border-style: solid;
    height: 9px;
    width: 9px;
    background-color: white;
    position: relative;
    opacity: 1;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px; }

    &.swiper-pagination-bullet-active {
      background-color: #888; } }

  &.is-hidden {
    display: none; } }
