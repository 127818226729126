.info-note {
  $this: &;

  position: relative;
  padding: 16px 40px 14px 52px;
  max-width: 824px;
  margin: 0 auto;
  background-color: $G-2;
  border: 1px solid $G-3;
  @include xs {
    padding-left: 48px;
    padding-right: 20px; }

  &__icon {
    position: absolute;
    left: 24px;
    top: 22px;
    color: $G-5;
    font-size: 17px;
    @include xs {
      left: 20px; } }

  &__text {
    font-size: 14px;
    line-height: 1.6;

    p + p {
      margin-top: 8px; } }

  &--warn {
    background-color: rgba(198, 67, 68, 0.04);
    border: 1px solid rgba(198, 67, 68, 0.6);

    #{$this}__icon {
      color: $red; } } }
