.item {
  $this: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  justify-content: flex-end;

  &__title {
    margin-bottom: 12px;
    @include semi;
    font-size: 14px;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    line-height: 1.29;
    transition: color 200ms ease-out; }

  &__img {
    font-size: 0; }

  &:hover {
    #{$this}__title {
      color: $error; } }

  &--img {
    #{$this}__img {
      min-width: 72px;
      max-width: 72px;
      min-height: 72px;
      max-height: 72px; } }

  &--brand {
    #{$this}__img {
      min-width: 120px;
      max-width: 120px; } } }
