.our-services {
  background-color: #f7f7f7;
  padding: 20px 20px 21px;
  margin-bottom: 25px;

  @include sm {
    margin: 0 -20px 40px;
    padding: 20px 25px 28px 29px; }

  &__title {
    margin-bottom: 19px; }

  &__item {
    &:not(:last-child) {
      margin-bottom: 17px; } } }
