.checkbox {
  $this: &;

  font-size: 13px;
  padding-left: 26px;
  cursor: pointer;
  position: relative;

  input {
    display: none;

    &:checked {
      + #{$this}__reverse:before {
        opacity: 1; } } }

  &__label {
    cursor: pointer; }

  &__reverse {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $G-5;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 5px;
      width: 6px;
      height: 9px;
      border-bottom: 2px solid $G-5;
      border-right: 2px solid $G-5;
      opacity: 0;
      transform: rotate(45deg);
      transition: opacity 150ms ease-out; }

    &.is-top {
      margin: 0;
      top: 0; } } }
