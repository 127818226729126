.auth {

  &__label {
    display: flex;
    align-items: center;

    .icon {
      font-size: 12px;
      color: black;
      margin-right: 8px; }

    &--button {
      cursor: pointer;
      padding-left: 2px;

      .icon {
        margin-top: 3px; } } }

  &__subtitle {
    margin-bottom: 9px; }

  &__forgot {
    background: transparent;
    &:hover {
      color: $red; } }

  &__form {
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;

    &--reg {
      padding-bottom: 12px; } }

  &__group {
    padding-top: 15px;

    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid #dcdcdc; } }

  &__teleport {
    font-size: 15px;
    color: $error;
    text-decoration: none;
    @include bold;
    cursor: pointer; }

  &__socials {
    display: flex;
    margin-top: 7px; }

  &__social {
    border: 1px solid $G-5;
    height: 44px;
    display: flex;
    text-decoration: none;
    color: black;
    padding: 5px 15px 5px 16px;
    align-items: center;
    font-size: 15px;
    &:hover {
      color: $red;
      border-color: $red; }

    &:not(:last-child) {
      margin-right: 8px; }

    .icon {
      font-size: 20px;
      margin-right: 12px; }

    img {
      margin-right: 12px; }

    &--facebook {
      .icon {
        fill: #3F5497; } } } }
