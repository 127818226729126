.product-swarm {
  position: relative;
  display: flex;
  align-items: center;

  &__image {
    max-width: 80px;
    min-width: 80px;
    max-height: 80px;
    min-height: 80px;
    margin-right: 20px; }

  &__tags {
    margin-bottom: 8px; }

  &__prices {
    display: flex;
    align-items: flex-end; }

  &__old-price {
    position: relative;
    top: -1px;
    margin-right: 8px;

    font-size: 13px;
    color: black;
    line-height: 1.67;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      background-color: black;
      left: 0;
      right: 0; } }

  &__price {
    font-size: 15px;
    @include semi;
    line-height: 1.67;
    margin-right: 9px;

    &--sale {
      color: $error; } }

  &__title {
    display: block;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 8px;
    &:hover {
      color: $red; } }

  &__note {
    margin-bottom: 4px;
    font-size: 11px;
    color: $G-5; }


  &__label {
    position: absolute;
    top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    line-height: 1;
    color: #ffffff;
    @include semi;
    font-size: 11px;
    border-radius: 14px;
    z-index: 9;

    &.is-discount {
      background-color: #D01000; }

    &.is-new {
      background-color: #31945E; } } }
