.alarm {
  $this: &;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

  &__image {
    font-size: 0; }

  &__title {
    text-align: center;
    @include semi;
    padding: 0 30px; }

  &__descr {
    font-size: 15px;
    line-height: 1.6;
    text-align: center; }

  &--sm {
    width: 100%;
    #{$this}__inner {
      min-height: 240px; }
    #{$this}__title {
      font-size: 18px;
      line-height: 1.56;
      margin-bottom: 6px; }
    #{$this}__image {
      margin-bottom: 21px; } }

  &--md {
    width: 100%;

    #{$this}__title {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 8px; }

    #{$this}__image {
      margin-bottom: 11px; } } }


.modal {
  $this: &;

  @include sm {
    overflow: auto; }

  &.alarm {
    #{$this}__content {
      padding-top: 10px;
      padding-bottom: 10px; }
    &--sm {
      #{$this}__content {
        max-width: 248px; } }
    &--md {
      #{$this}__content {
        min-height: 271px;
        max-width: 472px; } }
    &--callback {
      #{$this}__content {
        width: 360px;
        max-width: none;
        @include xs {
          width: 100%; } } } } }
