.dropdown {

  &__close {
    display: none;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    right: 12px;
    top: 12px;
    @include md {
      display: block; }

    .icon {
      fill: #666; } } }
