.action-buttons {
  display: flex;
  align-items: center;
  @include xs {
    flex-direction: column; }

  &__item {
    position: relative;
    padding: 4px 12px;
    @include xs {
      padding: 8px 12px; }

    & + & {
      border-left: 1px solid #DCDCDC;
      @include xs {
        border-left: none;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 1px;
          margin: 0 auto;
          height: 1px;
          width: 35px;
          background-color: #DCDCDC; } } } }


  &__inner {
    border-bottom: 1px dashed rgba(68, 68, 68, 0.5);
    cursor: pointer;
    text-decoration: none;
    color: #000000;
    font-size: 12px;
    line-height: 1.2;

    &:hover {
      color: $red;
      border-color: $red; } } }
