.short-product-preview {
  $this: &;

  display: flex;
  padding-top: 12px;
  padding-bottom: 11px;

  &__image {
    max-width: 60px;
    min-height: 60px;
    min-width: 60px;
    max-height: 60px; }

  &__body {
    padding-left: 16px; }

  &__heading {
    font-size: 14px;
    line-height: 1.29;
    margin-bottom: 5px; }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }

  &__count {
    font-size: 12px; }

  &__price {
    @include semi;
    font-size: 14px; }

  &:hover {
    #{$this}__heading {
      color: $error; } } }

