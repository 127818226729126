.field {
  $this: &;
  position: relative;


  &.is-error {
    #{$this}__input {
      border-color: $error; }
    .tooltip {
      opacity: 1;
      visibility: visible;
      display: block;
      &__inner {
        transform: translateY(0); }
      &[data-show] {
        opacity: 1;
        visibility: visible; } } }

  &__input {
    width: 100%;
    padding: 14px 17px;
    background-color: #ffffff;
    border: 1px solid $input_border;
    border-radius: $border-radius-tiny;
    font-size: 15px;
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    @include placeholder {
      color: $input_border_placeholder; }

    &:focus {
      border-color: $input_border_focus;
      color: #000000; } }


  &__tooltip-error {
    .tooltip {
      display: none;
      max-width: 203px;
      padding: 8px;
      line-height: 1.23;
      background-color: #FDF9E9;
      border-color: #EDD1B0;
      border-radius: 0;
      &[data-show] {
        opacity: 0; }
      &__arrow-inner {
        border-radius: 0;
        background-color: #FDF9E9;
        border-color: #EDD1B0; }

      &:before {
        background-color: #fdf9e9; } } }

  &--sm {
    #{$this}__input {
      padding: 12px 10px; } }

  &--mb-lg {
    &.is-error {
      margin-bottom: 60px; } }

  &--reverse {
    #{$this}__input {
      border: 1px solid $input_border_focus;

      &:focus {
        border-color: black; } } }


  & + & {
    margin-top: 9px; } }

// for bitrix forms
.form-group + .form-group {
  margin-top: 9px; }


.error-message {
  margin-bottom: 8px;
  color: $error;
  font-size: 14px; }
