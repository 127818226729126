.advantages {

  &__inner {
    display: flex;
    background-color: #F9F9F9;

    @include sm {
      flex-direction: column;
      margin: 0 -20px; } }

  &__image {
    width: 100%;
    max-width: 520px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include sm {
      padding-top: 72%;
      height: 0;
      max-width: 100%; } }

  &__info {
    padding: 55px 78px;

    @include xl {
      padding: 35px 78px; }

    @include sm {
      padding: 20px 20px 32px; } }

  &__title {
    margin-bottom: 32px;
    line-height: 1.1;

    @include sm {
      margin-bottom: 21px; } }

  &__button {
    margin-top: 35px;

    @include sm {
      margin-top: 29px;

      .button {
        width: 100%;
        padding: 13px 0px; } } } }
