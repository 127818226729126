.footer {
  position: relative;
  margin-top: auto;
  //z-index: 2
  background-color: #424242;

  &__container {
    display: flex;
    flex-wrap: wrap; }

  &__logo {
    background-color: white;
    position: relative;
    top: -14px;
    width: 245px;
    height: 88px;
    padding: 24px 33px 24px 32px;
    margin-right: 45px;

    @include xs {
      width: 140px;
      height: 50px;
      padding: 11px 21px; } }

  &__actions {
    @include md {
      margin-bottom: 12px; }

    @include xs {
      width: 100%;
      margin-bottom: 18px; } }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    @include xs {
      justify-content: space-between;
      margin-bottom: 3px; } }

  &__lang {
    position: relative;
    top: -9px;
    font-size: 13px;
    line-height: 2.47;
    color: white;
    text-decoration: none;
    &:hover {
      color: $yellow; }

    @include xs {
      font-size: 15px;
      line-height: 2.134;
      top: -3px; } }

  &__lang-short {
    display: none;

    @include xs {
      display: block; } }

  &__lang-long {
    display: block;

    @include xs {
      display: none; } }

  &__navigations {
    display: flex;

    @include xs {
      flex-direction: column; } }

  &__navigation {
    min-width: 221px;

    &:not(:last-child) {
      margin-right: 69px; }

    @include xs {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 28px; } } }

  &__contacts {
    margin-left: auto;
    margin-right: 70px;
    max-width: 440px;
    width: 100%;
    position: relative;
    top: -14px;

    @include xl {
      max-width: 380px; }

    @include md {
      top: 0;
      margin-right: 0;
      margin-left: 0; }

    @include xs {
      width: 100%;
      max-width: 100%; } }

  &__bottom {
    position: relative;
    flex-grow: 1;

    @include md {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      padding-bottom: 16px;
      width: 100%; } }

  &__socials {
    position: absolute;
    top: -141px;
    right: 0;
    flex-direction: column;
    display: flex;

    @include md {
      position: relative;
      top: 0;
      flex-direction: row;
      font-size: 0;
      margin-bottom: 19px; }

    @include xs {
      margin-bottom: 24px; } }

  &__social {
    .icon {
      fill: white;
      font-size: 24px; }

    &:hover {
      .icon {
        fill: $yellow; } }

    &:not(:last-child) {
      margin-bottom: 6px;

      @include md {
        margin-bottom: 0;
        margin-right: 20px; } } }

  &__credits {
    display: inline-flex;
    align-items: center;
    position: relative;
    top: -61px;

    @include md {
      top: 0; }

    @include xs {
      flex-direction: column;
      align-items: flex-start; } }

  &__navi {
    display: flex;
    margin-right: 46px;

    @include xl {
      margin-right: 20px; }

    @include xs {
      margin-right: 0;
      margin-bottom: 10px; } }

  &__nav {
    font-size: 12px;
    color: white;
    text-decoration: none;
    &:hover {
      color: $yellow; }

    &:not(:last-child) {
      margin-right: 16px; } }

  &__copy {
    font-size: 12px;
    color: white;
    line-height: 2; } }

