.contacts {
  &__item {
    position: relative;
    background-color: #947a7d;
    padding-left: 44px;
    padding-right: 44px;
    &:hover {
      &:before {
        transform: scaleX(1.02); } }

    &:before {
      content: '';
      z-index: 1;
      background-color: #947a7d;
      @include coverdiv;
      transform: scaleX(1);
      transition: transform .25s ease-in-out; }


    &:not(:last-child) {
      margin-bottom: 4px;

      @include xs {
        margin-bottom: 8px; } }

    @include xl {
      padding-left: 20px;
      padding-right: 20px; }

    @include xs {
      margin-left: -20px;
      margin-right: -20px; } }

  &__inner {
    position: relative;
    z-index: 2; }

  &__form {
    display: none;
    margin-top: 16px;
    margin-bottom: 16px;
    @include md {
      display: block; } }

  &__heading {
    color: white;
    line-height: 1.47;
    @include bold;
    font-size: 15px;

    a {
      @include font-default;
      color: white;
      &:hover {
        color: $yellow;
        text-decoration: none; } } }

  &__address {
    padding-top: 22px;
    padding-bottom: 23px;

    @include xs {
      padding-top: 20px;
      padding-bottom: 22px; } }

  &__links {
    padding-top: 26px;
    padding-bottom: 33px;

    @include xs {
      padding-top: 24px;
      padding-bottom: 24px; } }

  &__subscribe {
    padding-bottom: 32px;
    padding-top: 24px;

    @include xs {
      padding-top: 22px;
      padding-bottom: 24px; } }

  &__link {
    font-size: 15px;
    line-height: 1.6;
    color: black;
    text-decoration: none;
    @include semi;
    &:hover {
      color: $red; } }

  &__group {
    padding-bottom: 11px;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc; }

    &--msgs {
      padding-top: 11px;
      padding-bottom: 15px; }

    &--time {
      padding-top: 16px;
      border: none !important;
      @include md {
        border-bottom: 1px solid #dcdcdc !important; } } }

  &__email {
    color: black;
    text-decoration: none;
    display: inline-block;
    line-height: 1.34;
    font-size: 15px;
    margin-bottom: 14px;
    position: relative;
    font-weight: normal;
    &:hover {
      color: $red;
      &:before {
        background-color: rgba($red, .3); } }


    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(black, .3); } }

  &__messenger {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;

    .icon {
      fill: #398EF2;
      font-size: 18px;
      margin-right: 8px; } }

  &__phone {
    display: flex;
    align-items: center; }

  &__socials {
    margin-left: 10px;
    position: relative;
    top: 3px; }

  &__social {
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 8px; }

    .icon {
      font-size: 17px; }

    &--viber {
      .icon {
        fill: #6f4595; } }

    &--telegram {
      .icon {
        fill: #67A2D8; } } }

  &__time {
    line-height: 1.34;
    font-size: 15px;
    font-weight: normal; }

  &__note {
    font-size: 0.7em;
    margin-left: 10px;
    width: auto;
    white-space: nowrap;
    color: #444444;
    @include mdUp {
      display: none; } }

  &--relative {
    display: none;
    padding-bottom: 8px;
    margin-bottom: 16px;

    @include sm {
      display: block; } } }

