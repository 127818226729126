.products-tiles {
  $this: &;

  &__title {
    margin-bottom: 12px;

    @include sm {
      text-align: left;
      margin-bottom: 0; } }

  &__container {
    position: relative; }

  &__slider {
    margin: 0 -28px;
    @include sm {
      padding-bottom: 37px;
      position: relative;
      margin: 0 -20px;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 20px;
        right: 20px;
        background-color: #dcdcdc; } }

    &.is-pagination-hidden {
      @include sm {
        padding-bottom: 2px; }
      .swiper-pagination {
        display: none; } } }

  &__slide {
    @include sm {
      max-width: 275px; }

    &:hover {
      z-index: 2; } }

  &__arrows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    @include sm {
      display: none; } }

  &__arrow {
    position: absolute;
    top: 130px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    cursor: pointer;
    transition: opacity .2s ease, transform .2s ease;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    background: #FFFFFF;

    &:hover {
      svg {
        fill: $red; } }

    &.swiper-button-disabled,
    &.is-hidden {
      display: none; }

    svg {
      fill: $G-5;
      font-size: 24px; }

    &--prev {
      left: 20px;
      @include md {
        left: 28px; } }

    &--next {
      right: 20px;

      svg {
        transform: rotate(180deg); }

      @include md {
        right: 28px; } } }

  &__pagination {
    display: none;
    bottom: 5px !important;
    z-index: -1;

    @include sm {
      display: block; } }

  &__side-hide {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: calc(100% + 10px);
    top: -10px;
    bottom: 0;
    left: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      z-index: 3;
      background-color: #ffffff;
      pointer-events: auto; }

    &:before {
      right: 100%; }

    &:after {
      left: 100%; } }


  &--gutter {
    #{$this} __title {}
    margin-bottom: 5px; }

  &--tiny {
    #{$this} __slider {}
    margin: 0 -18px;

    #{$this}__arrow {
      &--prev {
        left: 10px; }

      &--next {
        right: 10px; } } }

  &--less-outer {
    .products-tiles__slider {
      margin: 0 -18px; } }

  &.has-border {
    border-bottom: 1px solid #DCDCDC;
    @include xs {
      border: none; } }

  &.is-max-width-180 {
    .products-tiles__slide {
      max-width: 220px; } }

  &.is-mob-max-width-180 {
    .products-tiles__slide {
      @include sm {
        max-width: 220px; } } }

  &.is-slides-auto {
    .swiper-container {
      @include smUp {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          width: 80px;
          height: 100%;
          background: linear-gradient(270deg, #FFFFFF 2.44%, rgba(255, 255, 255, 0) 100%); } } } }


  .swiper-container {
    overflow: visible; }


  @include rmin(768) {
    @for $i from 1 through 6 {
      $slide: ".swiper-slide";
      .swiper-container[data-slides="#{$i}"] {
        .swiper-slide {
          opacity: 0;
          visibility: hidden;
          transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

          &-active {
            opacity: 1;
            visibility: visible;
            @for $k from 1 through $i - 1 {
              & + #{$slide} {
                opacity: 1;
                visibility: visible;

                $slide: "#{$slide} + .swiper-slide"; } } } } } } }
  //+rmin(768)
  //  .swiper-container[data-slides="auto"]
  //    .swiper-slide
  //      opacity: 0
 }  //      visibility: hidden

