.notification {
  padding: 5px 20px;
  text-align: center;
  color: $G-0;
  font-size: 13px;

  @include xs {
    font-size: 12px;
    padding: 8px 20px;
    text-align: left;
  }

  &--info {
    background-color: #9A5362;
  }

  .header.is-sticky &.is-sticky {
    display: block;
    margin-top: -14px;
  }


  .header.is-sticky & {
    display: none
  }
}

.notify {
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%) translateY(100px);
  opacity: 0;

  width: 540px;
  padding: 10px 20px;

  background: #F5F0C1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  transition: all .3s ease;

  @include xs {
    width: calc(100% - 40px);
  }

  a {
    color: $red;
    font-weight: 600;
    text-decoration: none;
  }

  &.is-active {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}
