.contacts-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: -50%;
  z-index: 1;
  min-width: 237px;
  background-color: white;
  border-radius: 6px;
  padding: 19px 23px 10px 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease-in-out, margin .2s ease;
  margin-top: 10px;

  @include md {
    top: 100%;
    left: 0;
    transform: translate(0, 0);
    max-height: calc(100vh - 54px); }
  @include sm {
    overflow: auto;
    padding: 19px 20px 10px 20px;
    min-width: 256px;
    max-width: 100%; }

  &.is-open {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }

  .ps__thumb-y {
    right: 4px; }


  .field {
    &.is-error {
      margin-bottom: 50px; } } }
