.category-block {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transform: translateY(0);
  text-decoration: none;
  color: #000000;
  transition: transform .3s ease-in-out;
  &:hover {
    transform: translateY(4px);
    &:before {
      opacity: 0; }
    &:after {
      opacity: 1; } }

  &:before,
  &:after {
    content: '';
    @include coverdiv;
    z-index: -1;
    transition: opacity .25s ease; }

  &:before {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    opacity: 1; }

  &:after {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.32);
    opacity: 0; }

  &__image {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;

    @include md {
      height: 150px; }

    @include sm {
      height: auto;
      min-width: 76px;
      max-width: 76px; } }

  &__info {
    padding: 20px;

    @include md {
      padding: 20px 12px; }

    @include sm {
      padding-top: 10px;
      padding-bottom: 12px; } }

  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    @include semi;
    line-height: 1;
    &:hover {
      color: $red; }

    @include md {
      font-size: 16px;
      line-height: 1.25; }

    @include sm {
      margin-bottom: 6px; } }

  &__text {
    font-size: 14px;

    @include sm {
      font-size: 13px; } }

  @include sm {
    display: flex;
    flex-direction: row;
    min-height: 120px; } }
