.common-pagination {
  $this: &;

  &.is-hidden {
    display: none; }

  .swiper-pagination-bullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    color: #ffffff;
    @include semi;
    background: 0;
    opacity: 0.5;
    transition: opacity .15s ease-in-out;

    &.swiper-pagination-bullet-active {
      opacity: 1; }

    @include sm {
      width: 12px;
      height: 12px;
      opacity: 1;
      color: transparent;
      border-color: rgba(white, .5);
      background-color: white;
      margin-right: 8px;

      &.swiper-pagination-bullet-active {
        opacity: 1;
        background-color: $error; } }

    &:hover {
      opacity: 1; } }

  &--dark {
    .swiper-pagination-bullet {
      border-color: $G-5;
      color: $G-5;
      @include sm {
        color: transparent; } } }

  &--md {
    .swiper-pagination-bullet {}
    width: 28px;
    height: 28px; }

  &--static {
    position: static; } }

