.process {
  $this: &;

  position: relative;
  max-width: 300px;
  margin: 0 auto;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__count {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    color: $error;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include semi;
    margin-bottom: 14px;
    position: relative;
    background-color: white;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      display: block;
      z-index: -1;
      pointer-events: none; }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid $error;
      border-radius: 50%;
      z-index: -1;
      transform: scale(1);
      transition: transform 200ms ease-out;
      background-color: white; }

    @include md {
      margin-bottom: 12px; } }

  &__title {
    font-size: 15px;
    line-height: 1.34;
    max-width: 300px;
    text-align: center;

    @include md {
      font-size: 14px;
      line-height: 1.43; } }

  &__icon {
    position: absolute;
    left: calc(100% + 4px);
    bottom: calc(100% - 14px);
    transform: translate(0px, 0px);
    transition: transform 200ms ease-out;

    .icon {
      fill: black; } }

  &--query {
    left: -408px;

    #{$this}__icon {
      .icon {
        font-size: 40px; } }

    #{$this}__count {
      &:before {
        top: -9px;
        left: 48px;
        background-image: url("data:image/svg+xml,%3Csvg width='264' height='37' viewBox='0 0 264 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 34.8592C91 44 133 -45.0002 263 34.8592' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A");
        width: 264px;
        height: 37px; } }

    @include md {
      left: -74px;
      #{$this}__count {
        &:before {
          top: 24px;
          width: 104px;
          height: 93px;
          background-image: url("data:image/svg+xml,%3Csvg width='104' height='93' viewBox='0 0 104 93' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.85954C69 -5.9998 112 41.0004 100 92.0004' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } } } }


  &--consultation {
    top: -90px;
    left: -100px;

    #{$this}__icon {
      left: calc(100% + 10px);
      .icon {
        font-size: 40px; } }


    #{$this}__count {
      &:before {
        height: 31px;
        width: 388px;
        top: 17px;
        left: 43px;
        background-image: url("data:image/svg+xml,%3Csvg width='388' height='31' viewBox='0 0 388 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 20.8593C140 57.0001 138 -37.0001 387 20.8591' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } }

    @include md {
      top: 12px;
      left: 37px;

      #{$this}__count {
        &:before {
          top: 27px;
          left: -144px;
          width: 151px;
          height: 158px;
          background-image: url("data:image/svg+xml,%3Csvg width='151' height='158' viewBox='0 0 151 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M96 157C-5.99982 102 -14 36 18.0001 9C50.0001 -18 122 35 150 17' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } } } }

  &--check-out {
    top: -175px;
    right: -311px;

    #{$this}__icon {
      left: calc(100% + 11px);
      bottom: calc(100% - 26px);
      .icon {
        font-size: 40px; } }

    #{$this}__count {
      margin-bottom: 11px;

      &:before {
        width: 558px;
        height: 187px;
        top: 9px;
        left: -543px;
        background-image: url("data:image/svg+xml,%3Csvg width='558' height='187' viewBox='0 0 558 187' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M556 35.9999C388 3.99966 357.404 76.4828 318 98.9997C241 143 29 118.999 9.99999 180' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } }

    @include md {
      top: 54px;
      right: 10px;
      #{$this}__count {
        &:before {
          left: 44px;
          width: 164px;
          height: 207px;
          background-image: url("data:image/svg+xml,%3Csvg width='164' height='207' viewBox='0 0 164 207' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1599 32.503C80.9999 50.0003 130 70.0002 142 100C154.256 130.64 151 202 80.9999 188' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } }

      #{$this}__title {
        right: 22px;
        padding: 0 40px;
        position: relative; } } }

  &--contract {
    top: -108px;
    left: -241px;

    #{$this}__icon {
      left: calc(100% + 5px);
      bottom: calc(100% - 25px);
      .icon {
        font-size: 36px; } }

    #{$this}__count {
      margin-bottom: 10px;

      &:before {
        width: 321px;
        height: 42px;
        top: 2px;
        left: 40px;
        background-image: url("data:image/svg+xml,%3Csvg width='321' height='42' viewBox='0 0 321 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40.9999C108 21.0003 184 -23.9998 320 21.0002' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } }

    @include md {
      top: 74px;
      left: 66px;
      #{$this}__title {
        padding: 0 40px;
        position: relative;
        right: 28px; }

      #{$this}__count {
        &:before {
          left: -163px;
          top: 11px;
          width: 161px;
          height: 168px;
          background-image: url("data:image/svg+xml,%3Csvg width='161' height='168' viewBox='0 0 161 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.9999 167C-49.0002 103 15 -26.9998 160 7.33076' stroke='%23C64344' stroke-width='2' stroke-dasharray='5 4 5 4'/%3E%3C/svg%3E%0A"); } } } }

  &--delivery {
    top: -197px;
    right: -116px;

    #{$this}__icon {
      left: calc(100% + 9px);
      bottom: calc(100% - 22px);
      .icon {
        font-size: 28px; } }

    #{$this}__count {
      margin-bottom: 9px; }

    @include md {
      top: 118px;
      right: 53px;
      #{$this}__count {
        &:before {} } } }

  &:hover {
    #{$this}__count {
      &:after {
        transform: scale(1.1539); } }

    #{$this}__icon {
      transform: translate(8px, -8px); } } }

