.submenu {
  $this: &;

  display: none;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 100%;

  &--auto-width {
    right: auto; }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f7f7f7;
    &.is-simple {
      background-color: transparent; } }

  &__item {
    display: flex;

    &.is-active,
    &:hover {
      #{$this}__button {
        background-color: #C64344; }

      #{$this}__link {
        color: white; }

      #{$this}__arrow {
        transform: scale(1.25);
        .icon {
          fill: white; } }

      #{$this}__catalog {
        display: block !important; } } }

  &__button {
    cursor: pointer;
    padding: 16px 22px 16px 28px;
    min-height: 52px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 360px;
    z-index: 3;
    background-color: #ffffff; }


  &__link {
    display: inline-block;
    color: black;
    font-size: 15px;
    @include semi;
    line-height: 1.34;
    text-align: left; }

  &__arrow {
    margin-left: 25px;
    display: inline-block;
    transform: scale(1);
    font-size: 0;

    .icon {
      font-size: 16px;
      fill: #888; } }

  &__catalog {
    display: block;
    min-height: 100%;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.24);
    background-origin: content-box;
    background-size: 40vw;
    background-position: 67% -125px;
    background-repeat: no-repeat; }

  &--simple {
    width: 307px;
    right: auto;
    left: 50%;
    margin-left: -153px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.24);

    #{$this}__list {
      background-color: white; }

    #{$this}__item {
      &:not(:last-child) {
        border-bottom: 1px solid #dcdcdc; } } }


  &:not(#{$this}--visible) {
    // min-height: 478px

    #{$this}__catalog {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 360px;
      background-color: #f7f7f7;
      right: 0;
      display: none;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 360px;
        top: 0;
        bottom: 0;
        background-color: white;
        display: block;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
        z-index: 2; } }


    #{$this}__list {
      padding-top: 5px;
      position: relative; } }

  &:not(#{$this}--simple) {
    #{$this}__item {
      &:not(:last-child) {
        #{$this}__button {
          border-bottom: 1px solid #dcdcdc; } } } }

  &:not(#{$this}--simple):not(#{$this}--visible) {
    #{$this}__list {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 360px;
        top: 0;
        bottom: 0;
        background-color: white;
        display: block;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12); } } } }
