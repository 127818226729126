.product-tile {
  $this: &;

  position: relative;
  padding: 40px 28px 28px;
  border: 1px solid transparent;
  transition: border-color .3s ease;

  @include sm {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px; }

  &:before {
    content: '';
    @include coverdiv;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none; }

  &.is-available {
    #{$this}__availability {
      color: #138547; } }

  &.is-not-available,
  &.is-discontinue {
    #{$this}__image {
      opacity: .5; }
    #{$this}__tags {
      .product-tag__box {
        border-color: #DCDCDC;
        &:hover {
          border-color: #000000; } } }
    #{$this}__availability {
      color: #888888; } }

  &.is-not-available {
    #{$this}__simple-link {
      display: none !important; }
    #{$this}__action-btns {
      flex-basis: 100%;
      @include sm {
        flex-basis: auto; } } }

  &.is-custom-made {
    #{$this}__availability {
      color: #B75C09; }
    #{$this}__simple-link {
      display: none !important; }
    #{$this}__action-btns {
      flex-basis: 100%;
      @include sm {
        flex-basis: auto; } } }

  &.is-expected {
    #{$this}__availability {
      color: #B75C09; }
    #{$this}__simple-link {
      display: none !important; } }

  &.is-discontinue {
    #{$this}__availability {
      @include semi;
      font-size: 15px;
      margin-top: -5px;
      color: #000000; } }


  @include rmin(600) {
    &:hover {
      border-color: #DCDCDC;
      &:before {
        opacity: 1; }
      #{$this}__info {
        opacity: 1;
        visibility: visible;
        z-index: 10; }
      #{$this}__action-btns {
        visibility: visible;
        opacity: 1; } } }

  &__image {
    display: block;
    flex-shrink: 0;
    text-decoration: none;
    max-width: 220px;
    margin: 0 auto 20px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto; }

    .lazy {
      filter: initial; } }

  &__top-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid #DCDCDC; }

  &__tags {
    font-size: 0;
    .product-tag {
      margin-right: 4px;
      margin-bottom: 4px; } }

  &__label {
    position: absolute;
    top: 28px;
    left: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 11px;
    line-height: 1;
    color: #ffffff;
    @include bold;
    font-size: 13px;
    border-radius: 14px;
    z-index: 9;

    &.is-discount {
      background-color: #D01000; }

    &.is-new {
      background-color: #31945E; }

    @include sm {
      top: 11px;
      left: 19px; } }

  &__sales {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 42px;
    height: 52px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include xs {
      top: 0;
      left: 12px; } }


  &__content {
    #{$this}__simple-link {
      display: none; } }

  &__availability {
    font-size: 12px; }

  &__vendor-code {
    font-size: 12px;
    color: $G-5; }

  &__rating {
    margin-top: 5px; }

  &__title {
    display: block;
    margin-top: 5px;
    margin-bottom: 14px;
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    line-height: 1.34;
    transition: color .2s ease;
    &:hover {
      color: $red; } }

  &__buy {
    margin-right: 33px;
    &.button--primary {
      font-size: 15px; } }

  &__cost {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    //justify-content: space-between
    margin-top: 4px;
    @include semi;
    font-size: 18px;

    &.is-discount {
      color: $red; } }

  &__old-cost {
    margin-left: 12px;
    margin-right: auto;
    font-size: 14px;
    text-decoration: line-through;
    color: #000000; }

  &__dealer-label {
    margin-top: 8px;
    margin-bottom: 6px;
    margin-left: 8px;
    @include xs {
      margin-left: 0; } }

  &__retail {
    width: 100%;
    font-size: 13px;
    color: #000;
    font-weight: normal;
    margin-top: -1px;
    margin-bottom: 3px; }

  &__action-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    #{$this}__cost {
      @include xs {
        margin-bottom: 8px;
        margin-right: 6px; } }
    #{$this}__buy {
      display: none;
      @include sm {
        display: block;
        margin: 10px 0 10px auto; } } }

  &__actions {
    margin-top: 8px;
    &.is-adaptive {
      display: none;
      @include sm {
        display: flex; } }
    &.is-row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .product-tile__buy {
        margin-right: 10px; } } }

  &__actions,
  &__action-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

  &__action-btns {
    margin-top: 6px;
    margin-bottom: 6px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease;
    @include xs {
      opacity: 1;
      visibility: visible; }

    .user-notification__icon {
      .icon-heart {
        font-size: 20px; }

      .icon-compare {
        font-size: 23px; }

      &:hover {
        & > .icon {
          fill: $red; } } } }

  &__action-btn {
    &:not(:last-child) {
      margin-right: 20px; } }

  &__simple-link {
    font-size: 13px;
    text-decoration: none;
    color: #000000;
    &:hover {
      color: $red; } }

  &__middle-col {
    display: none;
    padding-right: 50px; }

  &__benefits {
    font-size: 13px;
    padding-top: 8px;
    margin-top: 7px;
    border-top: 1px solid #dcdcdc;

    li {
      position: relative;
      padding-left: 12px;
      line-height: 1.24;

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: $G-5;
        border-radius: 50%; }

      &:not(:last-child) {
        margin-bottom: 6px; } } }

  &__info {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: calc(100% + 2px);
    box-sizing: border-box;
    visibility: hidden;
    padding: 6px 28px 24px;
    top: calc(100% - 28px);
    left: -1px;
    background: #fff;
    box-shadow: 0 10px 12px -6px rgba(0, 0, 0, .12);
    border-bottom: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    transition: border-color .3s ease;
    @include xs {
      display: none; }

    #{$this}__actions {
      margin-bottom: 8px; } }

  &__details {
    width: 100%;
    font-size: 13px;
    color: $G-5;
    font-weight: normal;
    @include sm {
      margin-left: auto;
      text-align: right;
      margin-top: 8px;
      padding-left: 8px; }
    @include xs {
      width: 100%; }

    &.is-desktop {
      @include sm {
        display: none; } }

    &.is-mobile {
      display: none;
      @include sm {
        display: block; } } }


  &--tiny {
    padding-left: 18px;
    padding-right: 18px;
    &:hover {
      background-color: #fff; }

    .product-tile__info {
      padding-left: 18px;
      padding-right: 18px; }

    #{$this}__label {
      left: 18px; } }


  &--compare {
    padding: 32px 0 0;

    &:before {
      display: none; }

    &:hover {
      border-color: transparent; }

    #{$this}__label {
      top: 20px;
      left: 0; }

    #{$this}__image {
      img {
        height: 160px; } } } }
