.lazy-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: #ffffff;

  &__spinner {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border: 4px solid transparent;
    border-top-color: $red;
    border-bottom-color: $red;
    animation: 1s spin linear infinite; } }


.swiper-lazy-loaded + .lazy-placeholder,
.swiper-lazy-loaded .lazy-placeholder {
  display: none; }
