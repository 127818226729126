.catalog {
  $this: &;

  padding: 28px 20px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px); }

  &__item {
    padding: 0 20px;
    width: 25px;
    flex-basis: 25%;
    margin-bottom: 26px;
    display: flex; }

  &--full {
    padding: 14px 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.24);

    #{$this}__item {
      width: auto;
      flex-basis: auto;
      padding: 0 15px; } } }
