.mob-header {
  padding: 0 17px 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 54px;
  background-color: white;
  display: flex;
  align-items: center;
  display: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

  @include md {
    display: flex; }

  &__logo {
    min-width: 40px;
    max-width: 40px;
    height: 30px;
    margin-right: 26px;
    text-decoration: none; }

  &__button {
    display: block;
    margin-top: 4px;
    position: relative;
    background-color: transparent;
    &:hover {
      & > .icon {
        fill: $red; } }

    .icon {
      font-size: 22px;
      fill: black; }
    .check {
      position: absolute;
      right: -8px;
      top: -4px;
      .icon {
        fill: #ffffff;
        font-size: 6px; } } }

  &__menu {
    margin-left: auto;
    margin-top: 3px; }

  &__cart {
    position: relative;
    @include r(440) {
      position: static; } }

  &__contacts {
    margin-right: 24px; }

  &__search {
    margin-right: 24px; }

  &__user {
    margin-right: 29px; }

  &__badge {
    position: absolute;
    top: -5px;
    right: -8px; }

  &__fav,
  &__compare {
    .notification-dropdown {
      &.is-open {
        transform: translate(0, -19px);
        margin-left: 0; } } }

  &__bottom-info {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; }

  &__order {
    margin-left: 24px;
    margin-right: 40px;
    @include r(359) {
      margin-left: 0;
      margin-right: 20px; } }
  &__order-link {
    padding: 7px 15px;
    background-color: transparent;
    border: 1px solid #B4B4B4;
    border-radius: 30px;

    @include osans;
    font-size: 13px;
    font-weight: 600;
    color: #222222;
    text-transform: uppercase;
    text-decoration: none; } }

