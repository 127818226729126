.checkmark {
  height: 12px;
  width: 12px;
  background-color: $error;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  padding-top: 3px;
  padding-left: 1px;


  .icon {
    font-size: 7px;
    fill: white; }

  &--absolute {
    position: absolute; } }
