.modal {
  $this: &;
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1001;

  &__overlay {
    @include coverdiv;
    background-color: $overlay; }

  &__content {
    position: absolute;
    left: 50%;
    top: 30%;

    max-height: 100%;
    margin: 0;

    //overflow-y: auto
    transform: translate(-50%, -30%);
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    overflow: hidden;
    @include xs {
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transform: translate(0); }
    &--gray {
      background-color: #f9f9f9; } }
  &.is-faded {
    #{$this}__content {
      overflow-y: auto; } }
  &.is-in-transition {
    #{$this}__content {
      overflow: hidden; } }

  &__close {
    position: absolute;
    z-index: 2;
    background-color: transparent;

    .icon {
      fill: #666; } }

  &__title {
    margin-bottom: 16px;
    font-size: 20px;
    @include semi;
    color: #000000; }

  &--sm {
    #{$this}__content {
      padding-left: 24px;
      padding-right: 24px; }

    #{$this}__close {
      top: 10px;
      right: 10px;

      .icon {
        font-size: 16px; } } }


  &--md {
    #{$this}__content {
      padding-left: 36px;
      padding-right: 36px; }

    #{$this}__close {
      top: 16px;
      right: 16px;

      .icon {
        font-size: 20px; } } }

  &--column {
    #{$this}__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }

  &--column-left {
    #{$this}__content {
      display: flex;
      flex-direction: column; } }
  &--compare {
    #{$this}__content {
      width: 100%;
      max-width: 360px;
      padding: 40px 20px;
      @include xs {
        padding: 30px 20px; } }

    #{$this}__close {
      top: 14px;
      right: 14px;

      .icon {
        font-size: 16px; } } }

  .ps__thumb-y {
    right: 1px; }
  .ps__rail-y {
    display: block; } }


