.compare-groups-popup {
  &__title {
    margin-bottom: 17px;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.14;
    text-align: center;
    @include xs {
      font-size: 24px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.38;
    color: #000000;
    text-decoration: none;
    border-top: 1px solid #E0E0E0;

    &:last-child {
      border-bottom: 1px solid #E0E0E0;
    }

    &:hover {
      color: $red_hover;
    }
  }

  &__name {
    padding-right: 14px;
    color: #000000;
    text-decoration: none;
    &:hover {
      color: $red_hover;
    }
  }

  &__amount {
    margin-left: auto;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.69;
    color: #888888;
  }

  &__remove {
    background-color: transparent;
    font-size: 10px;
    &:hover {
      svg {
        fill: $red_hover;
      }
    }
  }
}
