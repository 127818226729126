.to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1001;
  background-color: #CDCDCD;
  border: 1px solid #CDCDCD;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out, opacity .3s ease-in-out;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  @include xs {
    right: 12px; }
  &:hover {
    background-color: #404040;
    border-color: #404040; }

  &.is-visible {
    opacity: 1;
    pointer-events: all; }

  &.is-footer {
    border-color: #dcdcdc;
    background-color: transparent;
    position: absolute;
    top: 18px;
    @include xs {
      top: auto;
      bottom: 80px; }

    .icon {
      fill: #dcdcdc; }

    &:hover {
      border-color: #dcdcdc;
      border-width: 2px;
      background-color: transparent; } }


  .icon {
    transform: rotate(180deg);
    fill: #ffffff;
    font-size: 12px;
    margin-bottom: 2px; } }
