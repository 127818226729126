.container {
  max-width: 1180px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto; }

.img-responsive {
  max-width: 100%;
  height: auto; }

.sidebar {
  width: 270px;

  @include md {
    .our-services,
    .advices,
    .category-menu {
      display: none; } } }

.hide-sm {
  @include sm {
    display: none; } }

