.cart-dropdown {
  $this: &;
  position: absolute;
  top: 100%;
  right: 0;
  width: 299px;
  padding-bottom: 12px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease;
  @include r(440) {
    transform: translate(0, 0); }
  @include md {
    left: 50%;
    top: calc(100% + 10px);
    transform: translate(-50%, 0); }
  @include r(440) {
    top: 100%;
    left: auto;
    transform: translate(0, 0); }


  &.is-open {
    opacity: 1;
    visibility: visible; }

  &__head {
    padding: 18px 24px 16px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      background-color: #dcdcdc;
      display: block; } }

  &__title {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 4px;
    @include semi; }

  &__description {
    font-size: 15px;
    margin-bottom: 8px; }

  &__list {
    position: relative;
    padding-left: 24px;
    max-height: 335px;
    overflow: hidden;

    & > div:first-child {
      position: relative;
      padding-right: 35px;
      max-height: 335px;
      overflow: hidden;
      margin-bottom: -1px; } }


  &__item {
    display: block;
    text-decoration: none;
    color: #000000;

    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc; } }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

  &__button {
    flex-shrink: 0; }

  &__to-cart {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #000000;
    text-decoration: none;
    line-height: 1.34;
    font-size: 14px;
    background-color: transparent;

    span {
      border-bottom: 1px solid rgba(0, 0, 0, .3); }

    &:hover {
      color: $red;

      span {
        border-bottom: 1px solid $red; } } } }
