.sh-select-label {
  display: block;
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  color: #666666;
  width: 100%
}

.ss-main .ss-single-selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 44px;
  padding-left: 13px;
  border: 1px solid $select;
  border-radius: $border-radius-tiny;
  background-color: $G-0;
  outline: 0;
  box-sizing: border-box;
  transition: background-color .2s;
}

.ss-main .ss-single-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed
}

.ss-main .ss-single-selected.ss-open-above {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  & + .ss-content {
    top: calc(100% - 2px);
  }
}

.ss-main .ss-single-selected.ss-open-below {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px
}

.ss-main .ss-single-selected.ss-open-above,
.ss-main .ss-single-selected.ss-open-below {
  .ss-arrow {
    transform: scale(-1);
  }
  .arrow-up {
    margin-top: -5px;
  }
}

.ss-main .ss-single-selected .placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: 100%;
  line-height: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 2px;
  font-size: 15px;
  color: #000000;
}

.ss-main .ss-single-selected .placeholder * {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto
}

.ss-main .ss-single-selected .placeholder .ss-disabled {
  color: #dedede
}

.ss-main .ss-single-selected .ss-deselect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 6px 0 6px;
  font-weight: bold
}

.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none
}

.ss-main .ss-single-selected .ss-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  margin: 0 14px 0 4px;
  transition: transform .2s ease;
}

.ss-main .ss-single-selected .ss-arrow span {
  display: inline-block;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  margin-top: -2px;
}

.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 30px;
  width: 100%;
  padding: 0 0 0 3px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  box-sizing: border-box;
  transition: background-color .2s;
}

.ss-main .ss-multi-selected.ss-disabled {
  background-color: #dcdee2;
  cursor: not-allowed
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666666;
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed;
}

.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ss-main .ss-multi-selected.ss-open-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ss-main .ss-multi-selected .ss-values {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px);
}

.ss-main .ss-multi-selected .ss-values .ss-disabled {
  display: flex;
  padding: 4px 5px;
  margin: 2px 0;
  line-height: 1em;
  align-items: center;
  width: 100%;
  color: #dedede;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.ss-main .ss-multi-selected .ss-values .ss-value {
  display: flex;
  user-select: none;
  align-items: center;
  font-size: 12px;
  padding: 3px 5px;
  margin: 3px 5px 3px 0;
  color: #ffffff;
  background-color: #5897fb;
  border-radius: 4px;
  animation-name: scaleIn;
  animation-duration: .2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {
  animation-name: scaleOut;
  animation-duration: .2s;
  animation-timing-function: ease-out
}

.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer
}

.ss-main .ss-multi-selected .ss-add {
  display: flex;
  flex: 0 1 3px;
  margin: 9px 12px 0 5px
}

.ss-main .ss-multi-selected .ss-add .ss-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform .2s
}

.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px
}

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  transform: rotate(45deg)
}

.ss-content {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  width: 100%;
  margin: -1px 0 0 0;
  box-sizing: border-box;
  border: solid 1px #c2c2c2;
  z-index: 1010;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  box-shadow: $sh-1;
}

.ss-content.ss-open {
  opacity: 1;
  visibility: visible;
}

.ss-content .ss-search {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  padding: 8px 8px 6px 8px
}

.ss-content .ss-search.ss-hide {
  height: 0;
  opacity: 0;
  padding: 0 0 0 0;
  margin: 0 0 0 0
}

.ss-content .ss-search.ss-hide input {
  height: 0;
  opacity: 0;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.ss-content .ss-search input {
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  height: 30px;
  padding: 6px 8px;
  margin: 0;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #ffffff;
  outline: 0;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: textfield;
}

.ss-content .ss-search input::placeholder {
  color: #8a8a8a;
  vertical-align: middle;
}

//.ss-content .ss-search input:focus {
//  box-shadow: 0 0 5px #5897fb;
//}

.ss-content .ss-search .ss-addable {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  box-sizing: border-box;
}

.ss-content .ss-addable {
  padding-top: 0;
}

.ss-content .ss-list {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  padding: 3px 13px;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 6px 10px;
  font-weight: bold;
}

.ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}

.ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #ffffff;
  background-color: $G-2;
}

.ss-content .ss-list .ss-option {
  cursor: pointer;
  height: auto;
  padding: 11px 0;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 15px;
  color: #000000;
  position: relative;

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      display: block;
      border-bottom: 1px solid #eeeeee
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -14px;
    right: -14px;
    top: -1px;
    bottom: 0;
    background-color: $G-2;
    opacity: 0;
    z-index: -1;
    border-radius: 3px;
  }

  //@include sm() {
  //  height: 38px;
  //}
}

.ss-content .ss-list .ss-option * {
  display: inline-block
}

.ss-content .ss-list .ss-option:hover,
.ss-content .ss-list .ss-option.ss-highlighted {
  //color: $link-default;

  &::before {
    opacity: 1;
  }
}

.ss-content .ss-list .ss-option.ss-disabled {
  font-weight: 600;
}

.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected {
  color: #666666;
  background-color: rgba(88, 151, 251, 0.1)
}

.ss-content .ss-list .ss-option.ss-hide {
  display: none
}

.ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: #fffb8c
}
