.cart-placeholder {
  $this: &;
  position: relative;
  display: flex;
  align-items: center;
  background: #F9F9F9;
  border: 1px solid #DCDCDC;
  border-bottom: none;
  width: 228px;
  min-height: 72px;
  padding: 7px 8px 13px 13px;
  cursor: pointer;

  &:hover {
    #{$this}__body {
      color: $red; } }

  &__icon {
    margin-top: 6px;

    .icon {
      font-size: 23px;
      fill: black; } }

  &__body {
    padding-left: 16px;
    color: #000000;
    transition: color .2s ease; }

  &__title {
    font-size: 16px;
    @include semi;
    line-height: 1.125;
    margin-bottom: 3px; }

  &__description {
    font-size: 13px;
    line-height: 1; } }
