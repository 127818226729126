.textarea {
  $this: &;

  position: relative;

  textarea {
    border: 1px solid #888;
    border-radius: 2px;
    background-color: white;
    resize: none;
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: #000000;
    @include font-default;

    &:focus {
      border-color: black; } }

  &__accelerator {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    font-size: 12px;
    line-height: 1.34;
    color: #666;
    text-align: right; }

  &--default {
    height: 120px;

    textarea {
      padding: 10px; } }

  &--semi {
    height: 100px;

    textarea {
      padding: 10px; } }

  &--md {
    height: 160px;

    textarea {
      padding: 12px; } } }
