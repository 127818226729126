.cart-promo {
  $this: &;

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 14px;
      line-height: 1;
      border-bottom: $border-dashed-gray; }

    .icon {
      margin-left: 5px;
      font-size: 6px; }

    @include hover {
      color: $red;
      .icon {
        fill: $red; }
      span {
        border-color: $red; } } }

  &__body {
    padding-top: 8px;
    display: none;

    &.is-active {
      display: block; } }

  &__row {
    display: flex;
    align-items: center;
    @include md {
      flex-direction: column;
      align-items: flex-start; } }

  &__field {
    .field {
      &__input {
        font-size: 14px;
        border-color: $G-4;
        padding: 10px 16px;
        width: 192px;
        height: 40px; } } }

  &__button {
    //+md
    //  width: 100%

    .button {
      font-size: 15px;
      height: 40px;
      margin-left: 8px;
      width: 120px;
      @include md {
        margin-left: 0;
        margin-top: 8px; }
 } }      //  width: 100%

  &__note {
    margin-bottom: 4px;
    font-size: 14px; }

  &__code {
    display: flex;
    align-items: center;
    font-size: 14px;
    @include bold;

    .icon {
      margin-right: 6px;
      font-size: 9px;
      fill: $green-0; } }

  &__sales {
    margin-top: 24px;
    padding-right: 28px;
    @include xs {
      padding-right: 0; } } }


.cart-promo-sales {
  display: flex;
  align-items: flex-start;
  font-size: 13px;

  &__icon {
    flex-shrink: 0;
    margin-right: 12px;
    font-size: 26px;
    min-width: 26px;
    max-height: 24px;
    @include xs {
      margin-right: 8px; }
    .icon {
      fill: $red; } }

  &__title {
    color: #000000;

    span {
      color: $red;
      @include semi; } }

  &__note {
    margin-top: 4px;
    color: $G-4;
    font-size: 12px;
    span {
      @include bold; } }

  & + & {
    margin-top: 2px; }

  &.is-discount {
    .cart-promo-sales__title {
      @include semi;
      color: $red; }
    .cart-promo-sales__note {
      color: #000000; } } }
