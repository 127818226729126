.tag {
  display: inline-flex;
  text-decoration: none;

  &--primary {
    height: 22px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 1.34;
    align-items: center;
    justify-content: center;
    color: $G-5;
    border: 1px solid $G-5;
    &:hover {
      color: $red;
      border-color: $red; } } }
