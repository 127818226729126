.button {
  $this: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 16px;

  font-size: $fz-default;
  @include font-default;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color .2s ease, background-color .2s ease;
  letter-spacing: .3px;

  @include sm {
    padding: 10px 16px;
    font-size: 15px; }

  &__icon {
    margin-left: 8px;
    font-size: 15px;
    svg {
      display: block;
      transition: fill .2s ease; } }

  &--primary {
    @include semi;
    background-color: $button_primary_bg;
    color: white;

    #{$this}__icon {
      .icon {
        fill: white; } }
    &:hover {
      background-color: $button_primary_hover; } }

  &--secondary {
    background-color: transparent;
    border-color: $button_secondary;
    @include semi;
    color: $button_secondary;
    #{$this}__icon {
      svg {
        fill: $button_secondary; } }
    &:hover {
      color: #ffffff;
      background-color: $red_hover;
      border-color: $red_hover;
      #{$this}__icon {
        svg {
          fill: #ffffff; } } }

    @include sm {
      border-width: 1px; } }

  &--light {
    border-color: #ffffff;
    color: #ffffff;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2); } }

  &--height-md {
    padding: 14px 22px;
    @include xs {
      padding: 14px 18px; } }

  &--height-tiny {
    padding: 14px 15px 16px; }

  &--height-sm {
    padding: 10px 15px 12px; }

  &--height-semi {
    padding: 8px 15px 10px; }

  &--height-xs {
    padding: 6px 15px 8px; }

  &--fz-md {
    font-size: 18px;

    @include sm {
      font-size: 15px; } }

  &--fz-semi {
    font-size: 16px; }

  &--fz-sm {
    font-size: 15px; }

  &--fz-xs {
    font-size: 14px; }

  &--space {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px; }

  &--black {
    border-color: black;

    #{$this}__text {
      color: black; }

    #{$this}__icon {
      svg {
        margin-top: 2px;
        font-size: 8px;
        fill: #000000; } }

    &:hover {
      border-color: $error;
      background-color: transparent;

      #{$this}__text {
        color: $red; }

      #{$this}__icon {
        svg {
          fill: $red; } } } }

  &--white {
    border-color: white;

    #{$this}__text {
      color: white; }

    &:hover {
      border-color: white;
      background-color: transparent;

      #{$this}__text {
        color: white; } } }

  &--hover-yellow {
    &:hover {
      color: $yellow;
      border-color: $yellow;
      #{$this}__text {
        color: $yellow; } } }

  &--outline-1 {
    border-width: 1px; }

  &--transparent {
    background-color: transparent;
    color: black;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background-color: transparent; } }


  &--tertiary {
    font-size: 13px;
    padding: 10px 15px 12px;
    @include semi;
    background-color: #EDD86B;
    &:hover {
      background-color: #E1C635; } }


  &--in-cart {
    padding: 10px 15px 12px;
    background-color: transparent;
    border-color: #138547;
    color: #138547;
    font-size: 13px;
    @include semi;
    #{$this}__icon {
      font-size: 9px;
      margin-right: 8px;
      margin-top: 2px;
      margin-left: -2px;
      svg {
        fill: #138547; } }
    &:hover {
      border-color: #C64344;
      color: #C64344;
      svg {
        fill: #C64344; } } }

  &--product {
    padding: 14px 40px;
    font-size: 18px;
    &.button--tertiary {
      padding: 15px 37px;
      margin: 0;
      font-size: 16px; } }

  &--narrow {
    max-width: 108px;
    width: 100%;
    padding: 8px 0 10px;
    font-size: 15px;
    line-height: 1.1; }

  &--left-icon {
    #{$this}__icon {
      margin-right: 8px;
      margin-left: 0; } }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none; } }


.cart-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 43px;
  background-color: $red;
  font-size: 22px;
  color: #ffffff; }
