.badge {
  border-radius: 50%;
  display: block;

  &--primary {

    background-color: $error;
    color: white;
    @include bold;
    font-size: 10px;
    line-height: 18px;
    width: 18px;
    height: 18px;
    text-align: center; } }
