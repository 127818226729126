.filter-tabs {
  $this: &;


  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include sm {
      flex-wrap: nowrap;
      //white-space: nowrap
      justify-content: flex-start;
      overflow-y: auto;
      margin-left: -10px; } }

  &__item {
    padding: 14px 20px;
    @include sm {
      padding: 6px 10px; }

    &.is-active {
      #{$this}__link {
        @include bold;
        &:before {
          opacity: 0; }
        &:after {
          opacity: 1; } } } }


  &__link {
    position: relative;
    padding-top: 6px;
    color: $G-5;
    text-decoration: none;
    @include semi;
    @include sm {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      @include semi; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;

      height: 1px;
      width: 100%;

      background-color: $G-3; }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;

      height: 2px;
      width: 100%;

      background-color: $G-5;
      opacity: 0; }

    &:hover {
      color: $red;
      &:before {
        opacity: 0; }
      &:after {
        opacity: 1;
        background-color: $red; } } }


  &__toggle {
    position: relative;
    display: none;
    align-items: center;
    margin-top: 12px;
    font-size: 14px;
    line-height: 1;
    background-color: transparent;

    .icon {
      font-size: 5px;
      fill: $G-5;
      position: absolute;
      left: calc(100% + 5px); }

    @include sm {
      display: flex; } }

  &__more {
    @include sm {
      display: none; } }

  &__toggle-more {
    color: $G-5;
    &:hover {
      color: $red; } }

  &__toggle-less {
    display: none;
    color: $G-5;
    &:hover {
      color: $red; } }


  &.is-expanded {
    #{$this}__list {
      flex-wrap: wrap; }

    #{$this}__item {
      overflow: hidden; }

    #{$this}__toggle-less {
      display: block; }

    #{$this}__toggle-more {
      display: none; }

    #{$this}__toggle {
      .icon {
        transform: rotate(180deg); } } }


  & + .common-description {
    margin-top: 50px; } }
