.subscribe {
  &__text {
    font-size: 12px;
    line-height: 1.34;
    color: white;
    padding-right: 30px;
    margin-bottom: 8px; }

  &__form {
    position: relative;
    display: flex; }

  &__field {
    border-radius: 2px;
    overflow: hidden;
    flex-grow: 1;
    input {
      height: 40px;
      width: 100%;
      background-color: white;
      padding: 0 15px;
      color: black;
      font-size: 15px;
      @include font-default;

      &::placeholder {
        color: #888; } }
    .tooltip {
      max-width: 280px; }

    .tooltip[data-popper-placement^='bottom'] {
      & > .tooltip__arrow {
        transform: translate(20px, 0px) !important; } } }

  &__button {
    background-color: $error;
    width: 124px;
    height: 40px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    &:hover {
      background-color: $red_hover; }

    &.has-sent {
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 7px;
        height: 12px;
        border: solid $G-0;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        margin-right: 10px;
        margin-bottom: 4px; } }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.8; } }

  &__captcha {
    position: absolute;
    bottom: 100%; } }
