.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: $overlay;
  opacity: 0;
  transition: opacity .25s ease;
  display: none;
  pointer-events: none;
  @include sm {
    display: block; }

  body.is-dropdown-opened & {
    opacity: 1;
    pointer-events: auto; } }
