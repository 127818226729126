.checkbox-control {
  $this: &;

  &__label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 28px;
    cursor: pointer;

    input {
      display: none;

      &:checked {
        + #{$this}__rect {
          background-color: $error;
          border-color: $error;

          &:before {
            opacity: 1; } }

        ~ #{$this}__text {
          font-weight: bold; } } } }

  &__text {
    font-size: 14px; }

  &__rect {
    width: 20px;
    height: 20px;
    border: 2px solid #dcdcdc;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: border 150ms ease-in-out, background 150ms ease-in-out;

    &:before {
      content: '';
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 5px;
      height: 10px;
      width: 6px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      transition: opacity 150ms ease-in-out;
      opacity: 0; } }

  &__color {
    height: 18px;
    min-width: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block; }

  &__hint {
    margin-left: 6px;
    position: relative;

    .tooltip {
      max-width: 264px;
      min-width: 264px;
      margin-left: -1px !important; } }

  &__tooltip-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 40px; }

  &.is-disabled {
    opacity: 0.4;
    pointer-events: none; } }
