.contact-phone {
  $this: &;

  display: flex;
  position: relative;

  &__phones {
    display: flex;
    align-items: center;
    margin-right: 34px;
    @include xs {
      display: block; } }

  &__tel {
    margin-right: 16px;
    position: relative; }

  &__link {
    font-size: 15px;
    line-height: 1.34;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    padding-top: 1px;
    cursor: pointer;
    @include semi;
    &:hover {
      color: $red; }

    .icon {
      font-size: 5px;
      margin-left: 6px; } }

  &__callback {
    font-size: 13px;
    padding-top: 2px;
    line-height: 1.54;
    cursor: pointer;
    font-weight: normal;
    &:hover {
      color: $red; } }

  &__note {
    font-size: 0.7em;
    position: absolute;
    width: auto;
    white-space: nowrap;
    color: #444444; }


  &.is-multiple {
    #{$this}__callback {
      padding-top: 1px; }
    #{$this}__link {
      &:not(:last-child) {
        margin-right: 20px;
        @include xxs {
          margin-right: 10px; } } } } }
