.autocomplete {
  z-index: 9999;
  max-height: 205px !important;
  margin-top: 2px;
  padding: 5px 0;
  overflow-y: auto;

  background-color: $G-0;
  box-shadow: $sh-2;
  border-radius: $border-radius-tiny;

  div {
    padding: 6px 20px;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background-color: $G-1; } } }


