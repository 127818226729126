.in-development {
  padding: 5px 20px;
  text-align: center;
  background-color: $dark_red;
  color: $G-0;
  font-size: 15px;
  @include xs {
    font-size: 12px;
    padding: 5px 10px; }

  .header.is-sticky & {
    display: none; } }
