.dealer-label {
  display: inline-block;
  padding: 4px 10px;
  background: #fff;
  border: 2px solid #DF9609;
  border-radius: 100px;
  font-size: 13px;
  color: #000;
  text-decoration: none;
  white-space: nowrap;

  &.is-semi {
    font-weight: 600;
  }
}
