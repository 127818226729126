@mixin osans {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: normal; }

@mixin mont {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: normal; }

@mixin light {
  font-weight: 300; }

@mixin semi {
  font-weight: 600; }

@mixin bold {
  font-weight: 700; }

@mixin extrabold {
  font-weight: 800; }


@mixin font-default {
  @include osans; }

$fz-default: 16px;

.nav-link {
  font-size: 13px;
  text-decoration: none;
  color: black; }

.label {
  font-size: 15px;
  line-height: 1.2; }

.heading {
  font-size: 18px;
  line-height: 1.34;
  @include semi;
  margin-bottom: 10px; }

.headline {
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: $G-5; }

.large-title {
  font-size: 22px;
  @include semi;
  margin-bottom: 13px;
  &.u-bold {
    font-weight: bold; } }

.legend {
  font-size: 22px;
  @include semi;
  line-height: 1.1; }

@mixin common-link {
  background-image: linear-gradient(rgba(black, .5),rgba(black, .5));
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% 1px;
  text-decoration: none;
  color: #000000;
  transition: color .15s ease;
  &:hover {
    color: $red;
    background-image: linear-gradient(rgba($red, .5),rgba($red, .5)); } }

.common-link {
  @include common-link; }
