.product-review-tile {
  $this: &;

  position: relative;
  padding: 40px 28px 28px;
  border: 1px solid transparent;
  transition: border-color .3s ease;

  @include sm {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px; }

  &__image {
    display: block;
    text-decoration: none;
    max-width: 220px;
    margin: 0 auto 20px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto; } }


  &__rating {
    margin-bottom: 2px; }

  &__title {
    display: block;
    @include semi;
    margin-top: 5px;
    margin-bottom: 2px;
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    line-height: 1.34;
    transition: color .2s ease;
    &:hover {
      color: $red; } }

  &__description {
    font-size: 15px;
    line-height: 1.3; }

  &__read-more {
    display: inline-block;
    margin-top: 6px;
    font-size: 15px;
    text-decoration: underline;
    color: #000000;
    &:hover {
      color: $red;
      text-decoration: none; } }

  &__meta {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: $G-5;
    font-size: 12px; }

  &__date {
    padding-left: 16px; } }



