.popular-goods {

  &__title {
    margin-bottom: 31px;

    @include sm {
      margin-bottom: 17px; } }

  &__list {
    display: flex;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px;

    @include sm {
      flex-direction: column;
      margin: 0; } }

  &__item {
    width: 270px;
    margin: 0 10px;
    min-height: 420px;

    @include md {
      min-height: auto; }

    @include sm {
      margin: 0;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px; } } } }
