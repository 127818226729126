.banner-slider {
  position: relative;

  @include sm {
    margin-bottom: 0; }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    min-height: inherit;
    padding: 0 20px;
    margin: 0 auto; }

  &__arrows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
    height: 100%; }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    cursor: pointer;
    transition: opacity .2s ease, transform .2s ease;

    svg {
      fill: #ffffff;
      font-size: 24px; }

    @include xl {
      display: none; }

    &--prev {
      left: -53px;
      &:hover {
        transform: translateX(-4px); }

      @include r(1230) {
        left: -35px; } }

    &--next {
      right: -53px;
      &:hover {
        transform: translateX(4px); }
      @include r(1230) {
        right: -35px; }

      svg {
        transform: rotate(180deg); } } }



  &__pagination {
    position: absolute;
    left: 0;
    bottom: 36px;

    @include r(1220) {
      left: 50%;
      transform: translateX(-50%); }

    @include sm {
      bottom: 7px; } } }


.banner-slider-slide {
  min-height: 380px;
  padding: 53px 0 67px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include sm {
    padding: 14px 0 29px;
    min-height: 177px; }

  &__title {
    max-width: 774px;
    font-weight: 800;
    margin-bottom: 8px;

    @include sm {
      font-size: 16px;
      line-height: 1.375;
      margin-bottom: 12px; } }

  &__description {
    max-width: 720px;
    line-height: 1.56;
    margin-bottom: 32px;

    @include sm {
      display: none; } }

  &__link {
    //max-width: 157px
    display: inline-block;
    align-self: flex-start;
    max-width: none;
    width: auto;
    margin-top: auto;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

    @include sm {
      max-width: 123px; } } }
