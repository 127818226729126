.reasons {
  $this: &;

  @include sm {
    padding-bottom: 9px; }

  &__title {
    margin-bottom: 28px;

    @include sm {
      margin-bottom: 21px; } }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    @include sm {
      padding: 0; }

    @include sm {
      flex-direction: column; } }

  &__item {
    width: 50%;
    padding-right: 50px;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
    &:nth-child(even) {
      margin-left: 30px;
      width: calc(50% - 30px);
      @include sm {
        width: 100%;
        margin-left: 0; } }
    &:hover {
      #{$this}__decor {
        &:first-child {
          transform: translate(-8px, -8px); }

        &:nth-child(2) {
          transform: translate(8px, 8px) rotate(180deg); } } }

    @include md {
      padding-right: 20px; }

    @include sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 14px; } } }

  &__icon {
    position: relative;
    margin-right: 36px;
    font-size: 44px;
    height: 80px;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include sm {
      font-size: 30px;
      margin-right: 17px;
      width: 63px;
      height: 53px; }

    & > svg {
      display: block;
      fill: #333333; } }

  &__decor {
    position: absolute;
    display: block;
    transition: transform .3s ease-in-out;
    transform: translate(0);

    svg {
      fill: none;
      font-size: 52px;

      @include sm {
        font-size: 27px; } }

    &:first-child {
      left: 0;
      top: 0;

      svg {
        stroke: #D7D7D7; }

      @include sm {
        left: -2px;
        top: -2px; } }

    &:nth-child(2) {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);

      svg {
        stroke: $red; } } }

  &__button {
    margin-top: 25px;
    @include sm {
      margin-top: 24px; }

    .button {
      padding: 14px 12px; } }

  &__tooltip-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
    padding-left: 5px;
    cursor: pointer;
    svg {
      display: block;
      fill: #000000;
      transition: fill .2s ease-in; }
    &:hover {
      svg {
        fill: #B94244; } } }

  &__tooltip-content {
    .tooltip {
      max-width: 420px;
      @include xs {
        max-width: none;
        width: 100%; } } }

  &__text {
    line-height: 1.5;

    .tippy-tooltip {
      min-width: 420px; }

    .tippy-content {
      padding: 20px 24px 20px 20px;
      font-size: 13px; }

    @include sm {
      font-size: 14px;
      line-height: 1.43; } } }
