.common-list {
  li {
    padding-left: 20px;
    position: relative;
    font-size: 15px;

    & + li {
      margin-top: 4px; }

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 3px;
      width: 6px;
      height: 10px;
      border-bottom: 2px solid #138547;
      border-right: 2px solid #138547;
      transform: rotate(45deg); } } }
