.radio {
  $this: &;

  & + & {
    margin-top: 16px; }

  &__input {
    display: none;

    &:checked ~ #{$this}__title {
      @include semi; }

    &:checked ~ #{$this}__circle {
      border: 2px solid $G-5;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $G-5; } } }

  &__circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000000; }

  &__title {
    font-size: 14px; }

  &__label {
    position: relative;
    padding-left: 28px;
    cursor: pointer; } }
