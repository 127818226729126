.f-contact {
  &__infos {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;

    @include xs {
      margin-bottom: 25px; } }

  &__item {
    display: flex;
    flex-direction: column; }

  &__link {
    color: white;
    @include bold;
    text-decoration: none;
    line-height: 1.375; }

  &__button {
    .button {
      padding: 8px 13px 10px;
      font-weight: normal; } }

  &__callback {
    font-size: 15px;
    line-height: 1.34;
    color: white;
    margin-top: 5px;
    text-decoration: none;
    position: relative;
    &:hover {
      color: $yellow;
      &:before {
        opacity: 0; } }

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      background-color: #af9b9d;
      height: 1px; } }

  &__socials {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px; }

  &__social {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    &:hover {
      border-color: #ffffff; }

    &:not(:last-child) {
      margin-right: 16px; }

    .icon {
      fill: white; }

    &--viber {
      background-color: #8e4ec9;
      border-color: #aa7ad7;

      .icon {
        font-size: 20px; } }

    &--messenger {
      background-color: #37548d;
      border-color: #697fa9;

      .icon {
        font-size: 18px; } }

    &--telegram {
      background-color: #158AC5;
      border-color: #4fa7d4;

      .icon {
        font-size: 18px; } } }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__email {
    color: white;
    text-decoration: none;
    line-height: 1.74;
    font-size: 15px;
    &:hover {
      color: $yellow; }

    @include xs {
      margin-left: 10px; } } }
