.header {
  $this: &;

  background-color: white;
  z-index: 9;
  position: relative;

  &__wrapper {
    @include md {
      display: none; } }

  &__top {
    padding-top: 7px;
    padding-bottom: 5px;
    display: flex; }

  &__nav {
    margin-right: 36px; }

  &__notifications {
    margin-top: -3px;
    display: flex;
    align-items: center;

    @include xl {
      margin-left: auto; }

    &--sticky {
      margin-left: auto;
      margin-top: 0; } }

  &__notification {
    position: relative;

    &:not(:last-child) {
      margin-right: 29px; }

    &--cart {
      height: 48px;
      width: 56px;
      background-color: #f7f7f7;
      border: 1px solid #ebebeb;
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      padding-right: 6px; }


    &--sticky {
      &:not(:last-child) {
        margin-right: 24px; } }

    &:not(#{$this}__notification--cart) {
      margin-top: 6px; } }

  &__logo {
    max-width: 160px;
    text-decoration: none;
    margin-left: -2px;
    padding-top: 4px;
    font-size: 0;
    display: inline-block;
    margin-right: 35px;

    &--sticky {
      max-width: 123px;
      height: 28px;
      padding-top: 0;
      margin-top: -2px;
      margin-left: -3px;
      margin-right: 32px; } }

  &__phones {
    display: flex;
    align-items: center;
    margin-right: 17px; }

  &__contacts {
    display: flex;
    align-items: center; }

  &__phone {
    font-size: 14px;
    line-height: 1.43;
    color: black;
    @include semi;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 26px; } }

  &__call-me {
    font-size: 13px;
    line-height: 1.54;
    color: black;
    padding-top: 2px;
    cursor: pointer;
    background-color: transparent;
    &:hover {
      color: $red; } }

  &__cab {
    margin-left: auto;
    text-decoration: none;
    font-size: 13px;
    line-height: 1.54;
    @include semi;
    text-align: right;
    color: black;
    padding-top: 2px;
    &:hover {
      color: $red; } }

  &__body {
    display: flex;
    align-items: center;
    padding-bottom: 2px; }

  &__search {
    margin-right: 49px;

    @include xl {
      margin-right: 20px; } }

  &__cart {
    margin-left: auto;
    position: relative;
    top: 2px;

    @include xl {
      margin-left: 30px; } }

  &__sticky {
    display: none;
    align-items: center;
    height: 48px;
    .user-notification {

      &__icon {
        &:hover {
          & > .icon {
            fill: $red; } } } }

    .compare-dropdown {
      &.is-open {
        right: auto;
        left: 0; } }

    .notification-dropdown {
      margin-top: 8px;
      margin-left: -170px; }
    .header__phone {
      &:hover {
        color: $red; } } }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    margin-top: -88px;
    transition: transform 250ms ease-out;

    #{$this}__wrapper {
      display: none; }

    #{$this}__sticky {
      display: flex; }

    .dealers-mob-header {
      display: none; } }

  &.is-out {
    transform: translateY(100%); }

  &.is-authorized {
    #{$this}__cab {
      display: flex;
      align-items: center;
      svg {
        font-size: 16px;
        margin-right: 8px; }
      &:hover {
        #{$this}__cab-user {
          &:before {
            background-image: none; } } } }
    #{$this}__cab-user {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: -3px;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, transparent, transparent 50%, rgba(68, 68, 68, 0.5) 50%, rgba(68, 68, 68, 0.5) 100%);
        background-size: 9px 1px; } } }

  &.is-product.is-sticky {
    margin-top: -62px;
    .menu {
      display: none; }
    .header__sticky {
      height: 62px; } }

  &.is-dealers {
    @include md {
      height: 170px; }
    @include xs {
      height: 100px; }
    @include r(504) {
      height: 120px; }
    @include r(352) {
      height: 134px; }
    #{$this}__cart {
      margin-left: auto; }
    .header__search + .header-dealer-actions {
      @include xl {
        display: none; } } }

  &.is-dealers-simple {
    @include md {
      height: 74px; }
    @include r(504) {
      height: 92px; }
    @include r(352) {
      height: 108px; }
    .header__search + .header-dealer-actions {
      @include xl {
        display: none; } }
    .header__search {
      flex-grow: 1;
 } } }      //+md

