.form-group {
  &__fields {
    margin-bottom: 7px;

    &--gutter {
      margin-bottom: 16px; }

    &--expand {
      margin-bottom: 20px; } }

  &__label {
    margin-bottom: 5px; }

  &__inner {
    &--pad-b-common {
      padding-bottom: 20px; } }

  &__field {
    &:not(:last-child) {
      margin-bottom: 15px; }

    &--fix-width {
      max-width: 320px;

      @include sm {
        max-width: 100%; } }

    &--inline {
      display: inline-block; }

    &--name {
      max-width: 124px;
      margin-right: 8px;
      flex-shrink: 0;
      @include r(400) {
        max-width: 120px; } }

    &--surname {
      max-width: 188px;
      @include r(400) {
        max-width: none;
        width: 100%; } }

    &--house-number {
      max-width: 88px;
      margin-right: 8px;
      flex-shrink: 0;
      @include r(400) {
        max-width: calc(50% - 4px); } }

    &--flat-number {
      width: 100%;
      max-width: 224px;
      @include r(400) {
        max-width: calc(50% - 4px); } }

    &--street {
      width: 100%; }

    &--mb-common {
      margin-bottom: 20px; }

    &--toggle {
      display: none; } }

  &__link {
    font-size: 13px;
    margin-bottom: 15px; }

  &__actions {
    display: flex;
    align-items: center; }

  &__action {
    &:not(:last-child) {
      margin-right: 20px; } }

  &__link {
    font-size: 13px;
    text-decoration: none;
    color: black; }

  &__rules {
    color: $G-5;
    font-size: 12px;
    line-height: 1.34;
    margin-top: 13px;

    a {
      color: $G-5; } }

  &__message {
    font-size: 11px;
    line-height: 2.19;
    color: $G-5;
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    .icon {
      margin-right: 6px;
      fill: $G-5;
      font-size: 14px; } }

  &__note {
    display: none;
    margin-top: 12px;
    font-size: 13px;
    p + p {
      margin-top: 2px; } }

  &__terms {
    margin-top: 12px;
    font-size: 12px;
    color: $G-5;

    a {
      text-decoration: underline;
      color: inherit;
      &:hover {
        text-decoration: none; } } }

  &__toggler {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
    cursor: pointer;

    span {
      border-bottom: $border-dashed-gray; }

    .icon {
      margin-left: 4px;
      margin-top: 2px;
      font-size: 5px; }

    &.is-active {
      .icon {
        margin-top: 1px;
        transform: scale(-1); } } } }
