.filter {
  $this: &;

  margin-bottom: 32px;

  @include md {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;

    transition: opacity 100ms ease-in-out, visibility 300ms ease-in-out;

    &.is-open {
      visibility: visible;
      opacity: 1;

      #{$this}__cover {
        opacity: 1; }

      #{$this}__inner,
      #{$this}__actions {
        transform: translateX(0); } } }

  &__inner {
    position: relative;
    z-index: 2;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 4px 20px 21px;
    background-color: white;

    @include md {
      width: 100%;
      max-width: 288px;
      padding: 4px 28px 80px;
      height: 100%;
      border-radius: 4px 4px 0 0;

      overflow-y: auto;
      overflow-x: hidden;

      transform: translateX(-100%);
      transition: transform 300ms ease-in-out; } }

  &__list {
    margin-bottom: 15px; }

  &__item {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 16px;

    &.is-active {
      padding-bottom: 23px;

      #{$this}__arrow {
        transform: rotate(180deg); }

      #{$this}__group,
      #{$this}__range {
        display: block; }

      #{$this}__toggle {
        display: flex; } }

    &.is-open {
      #{$this}__hide {
        display: block; } }

    &--hide {
      display: none; } }

  &__label {
    font-size: 16px;
    line-height: 1.19;
    width: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 16px; }

  &__hint {
    display: flex;
    margin-left: 3px;
    font-weight: 400;
    @include sm {
      display: none; }

    .tooltip {
      max-width: 264px; }
    &:hover {
      .filter__tooltip-content {
        z-index: 1; } } }

  &__tooltip-content {
    font-weight: 400;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 40px;
    z-index: -1; }

  &__arrow {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 0;
    display: flex;
    .icon {
      font-size: 6px;
      fill: black; } }

  &__toggle {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: -4px;
    display: none;

    .icon {
      font-size: 5px;
      margin-left: 4px;
      fill: black; }

    &.is-active {
      margin-top: 15px; } }

  &__group {
    margin-top: 13px;
    display: none; }

  &__control {
    &:not(:last-child) {
      margin-bottom: 13px; } }

  &__hide {
    display: none; }

  &__more {
    font-size: 14px;
    color: black;
    align-items: center;
    cursor: pointer;

    &:not(#{$this}__toggle) {
      display: flex; }

    .icon {
      fill: black;
      margin-left: 4px;
      font-size: 5px;
      margin-top: 3px; }

    &.is-active {

      .icon {
        transform: rotate(180deg) translateY(1px); }

      #{$this}__more-unless-text {
        display: flex; }

      #{$this}__more-text {
        display: none; } } }

  &__more-unless-text {
    display: none; }

  &__range {
    margin-top: 26px;
    margin-bottom: -6px;
    display: none; }

  &__range-value {
    margin-top: 10px; }

  &__selected {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 18px;

    @include md {
      padding-bottom: 9px; } }

  &__labels {
    margin-top: 15px;
    margin-bottom: 11px; }

  &__clear {
    font-size: 12px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 8px;
      fill: black;
      margin-left: 4px; }

    &:hover {
      color: $error;

      .icon {
        fill: $error; } }

    @include md {
      display: none; } }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(6, 27, 57, 0.6);
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    display: none;

    @include md {
      display: block; } }

  &__close {
    position: fixed;
    left: 296px;
    top: 13px;
    z-index: 1000;
    display: none;
    pointer-events: none;

    @include md {
      display: block; }

    .icon {
      fill: white;
      font-size: 17px; } }

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 52px;
    display: flex;
    z-index: 1003;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12);
    max-width: 288px;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out; }

  &__action {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    @include font-default;

    &--primary {
      background-color: $error;
      color: white;
      font-weight: bold;
      font-size: 15px;
      max-width: 160px;
      min-width: 160px;

      span {
        font-weight: 400;
        margin-left: 4px;
        color: #f4d9da; } }

    &--secondary {
      background-color: #f0f0f0;
      font-size: 13px;
      line-height: 1.23;
      color: $G-5;
      text-align: center;
      border-radius: 0 4px 0 0; } }

  .checkbox-control {
    &__hint {
      @include sm {
        display: none; } } } }

