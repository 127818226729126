.cart-process-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 20px 25px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;

  border-top: 1px solid $G-3;
  background-color: #F7F7F7;

  @include xs {
    padding-top: 10px;
    padding-bottom: 15px; }

  @include xsUp {
    display: none; }

  &__info {
    margin-right: 16px; }

  &__total {
    font-size: 16px;
    @include semi; }

  &__sum {
    font-size: 18px;
    @include bold;
    span {
      font-size: 24px; } }

  &__action {
    margin-left: auto;
    @include xs {
      margin-top: 10px;
      margin-bottom: 10px; }
    .button {
      background-color: $red;
      font-size: 18px; } }

  &__sales {
    margin-bottom: 12px; }

  &__old-cost {
    margin-top: -2px;
    font-size: 13px;
    text-decoration: line-through; }

  &__discount {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 16px;
    color: $green-0;
    font-size: 13px;
    @include semi;
    line-height: 1.2;
    span:first-child {
      margin-right: 10vw; } } }
