.advices {
  $this: &;

  background-color: white;
  border: 1px solid #dcdcdc;
  padding: 20px 20px 23px;

  &__heading {
    margin-bottom: 19px; }

  &__item {
    text-decoration: none;
    display: block;

    &:not(:last-child) {
      margin-bottom: 24px; }

    &:hover {
      #{$this}__title {
        color: $error;
        background-image: linear-gradient($red, $red); } } }

  &__image {
    height: 140px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 7px;

    @include sm {
      height: 0;
      padding-top: 61.2%; } }

  &__title {
    display: inline;
    background-image: linear-gradient(#808080, #808080);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 1px;
    font-size: 16px;
    line-height: 1.25;
    color: $G-5; }


  &--lg {
    border: none;
    padding: 0;

    #{$this}__container {
      max-width: 1075px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto; }

    #{$this}__list {
      display: flex;
      justify-content: space-between;
      margin-left: -20px;
      margin-right: -20px;
      @include sm {
        display: block;
        margin-left: 0;
        margin-right: 0; } }

    #{$this}__image {
      height: 195px;
      overflow: hidden;
      @include sm {
        padding: 0;
        height: 195px; } }

    #{$this}__image-inner {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      transform: scale(1);
      transition: all .7s ease; }

    #{$this}__item {
      width: calc(100%/3);
      margin-left: 20px;
      margin-right: 20px;
      @include sm {
        width: 100%;
        max-width: 320px;
        margin-left: 0;
        margin-right: 0; }

      &:hover {
        #{$this}__image-inner {
          transform: scale(1.1); } } }

    #{$this}__title {
      font-size: 15px; } } }
