.product-tag {
  $this: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #000000;

  &.is-active {
    #{$this}__box {
      border-color: #000000;
      background-color: #F7F7F7; }
    &.is-disabled {
      #{$this}__box {
        border-color: #000000; } } }

  &:hover {
    #{$this}__box {
      border-color: #000000; } }

  &__input {
    display: none; }

  &__box {
    padding: 3px 11px 4px;
    width: 100%;
    height: 100%;
    border: 1px solid #CDCDCD;
    background-color: transparent;
    font-size: 15px; }

  &__tooltip-content {
    pointer-events: none;

    .tooltip__arrow-inner {
      border-radius: 2px; }

    .tooltip[data-popper-placement^='bottom'] {
      margin-top: -10px !important; } }


  &.is-disabled {
    //pointer-events: none
    #{$this}__box {
      border-color: #DCDCDC;
      color: #888888; } } }
