.search {
  position: relative;

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.24);
    border: 1px solid #ebebeb;
    background-color: white;
    width: 528px;
    padding-bottom: 10px;
    @include sm {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 99px); } } }

.search-mob {
  display: none;
  @include md {
    position: absolute;
    top: 100%;
    width: 529px;
    z-index: 2;
    &.is-open {
      display: block; } }

  @include sm {
    width: 100%;
    left: 0;
    box-shadow: 0 12px 24px rgba(0, 0, 0, .24);
    border: 1px solid #ebebeb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; } }



