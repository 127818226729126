.modal {
  $this: &;


  .field__tooltip-error .tooltip {
    max-width: 100%; }
  .field {
    .tooltip__arrow {
      transform: translate(20px,0)!important; }

    .tooltip[data-popper-placement^='bottom-start'] {
      .tooltip__arrow {
        left: 0 !important; } }

    &.is-error {
      margin-bottom: 50px; }

    &.field--mb-lg {
      &.is-error {
        margin-bottom: 60px; } } }

  &.callme-popup {
    #{$this}__content {
      width: 246px;
      padding-top: 19px;
      padding-bottom: 31px;
      @include r(400) {
        width: 100%; } }
    &__heading {
      margin-bottom: 10px; } }

  &.question-popup {
    #{$this}__content {
      width: 595px;
      padding-top: 35px;
      padding-right: 39px;
      padding-bottom: 43px;
      @include md {
        width: 100%;
        max-width: 595px; }
      @include sm {
        padding: 20px 20px 32px; } } }
  &.auth-dropdown {
    .form-group__actions {
      @include xs {
        flex-wrap: wrap; } }

    .field {
      .tooltip__arrow {
        transform: translate(20px,0)!important; }
      &.is-error {
        margin-bottom: 65px; } } }

  &--default-size {
    #{$this}__content {
      width: 376px;
      padding: 23px 28px;
      @include r(400) {
        width: 100%;
        padding: 23px 26px; } } } }

.question-popup {

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 12px; }

  &__attention {
    font-size: 11px;
    line-height: 1.46;
    color: $G-5;
    position: relative;
    top: 1px; }

  &__title {
    padding-right: 5px; } }



.buy-popup {
  &__inner {
    display: flex;
    @include sm {
      display: block; } }
  &__product {
    display: block;
    text-decoration: none;
    color: #000000;
    max-width: 200px;
    padding-top: 30px;
    @include sm {
      max-width: 160px; } }
  &__heading {
    font-size: 22px; }
  &__product-title {
    font-size: 15px; }
  &__product-image {
    max-width: 80px;
    margin-bottom: 4px;
    img {
      max-width: 100%; } }
  &__form {
    flex-grow: 1;
    @include sm {
      margin-right: 0; } }
  .form-group__message {
    margin-top: 18px; }
  .field__input {
    max-width: 180px;
    @include xs {
      max-width: 320px; } }
  .modal {
    &__content {
      max-width: 500px;
      width: 100%;
      padding-top: 28px;
      padding-bottom: 28px; } } }



.marking-popup {
  .modal {
    &__content {
      padding: 16px 20px; }
    &__close {
      top: 16px;
      right: 16px; } } }
