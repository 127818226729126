.menu {
  $this: &;

  border-top: 1px solid #DCDCDC;
  background-color: white;

  @include md {
    display: none; }

  &__head {
    position: relative; }

  &__list {
    display: flex;
    margin: 0 -19px;
    @include xl {
      margin: 0 -8px; } }

  &__item {
    padding: 0 19px;

    &.is-active,
    &:hover {
      #{$this}__title {
        color: #C64344;
        &:before {
          opacity: 1; } } }

    &--simple {
      position: relative; }

    @include xl {
      padding: 0 8px; } }


  &__title {
    height: 49px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 6px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.25;
    color: black;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: -10px;
      right: -10px;
      height: 2px;
      background-color: #C64344;
      opacity: 0;
      transition: opacity 200ms ease-out;

      @include xl {
        left: 0;
        right: 0; } }

    @include xl {
      font-size: 15px; } }

  &.is-sticky {
    #{$this}__title {
      height: 39px;
      font-size: 15px; }

    #{$this}__list {
      justify-content: center; } } }
