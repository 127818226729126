.advantages-list {
  $this: &;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 25px; }

    &:hover {
      #{$this}__counter-inner {
        transform: translateX(16px);
        @include sm {
          transform: translateX(6px); } }
      #{$this}__counter {
        &:after {
          transform: scale(1.33); }
        &:before {
          transform: scaleX(1.14);
          @include sm {
            transform: scaleX(1.06); } } } } }



  &__counter {
    position: relative;
    margin-right: 10px;
    width: 60px;
    flex-shrink: 0;
    font-size: 60px;
    line-height: 1;
    @include mont;
    @include light;
    color: $red;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: $red; }

    &:before {
      bottom: 9px;
      left: calc(-100% - 20px);
      width: 112px;
      height: 1px;
      transition: transform .2s ease;
      transform: scaleX(1);
      transform-origin: left; }

    &:after {
      right: calc(100% + 72px);
      bottom: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(1);
      transition: transform .2s ease; }

    @include md {
      font-size: 50px;
      width: 50px;
      margin-right: 10px;

      &:before {
        width: 105px;
        bottom: 8px;
        left: calc(-100% - 25px); } }

    @include sm {
      font-size: 60px;
      width: 43px;
      &:before {
        left: calc(-100% - 31px); }

      &:after {
        right: calc(100% + 15px); } } }


  &__counter-inner {
    display: block;
    transform: translateX(0);
    transition: transform .2s ease; }


  &__title {
    @include semi;
    font-size: 18px; }

  &__link {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    @include semi;
    display: inline;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 1px;
    &:hover {
      background-image: linear-gradient($red, $red);
      color: $red; } }


  &__text {
    margin-top: 3px;
    font-size: 14px;

    @include sm {
      margin-top: 5px; } } }
