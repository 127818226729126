.cart-actions {
  display: flex;
  align-items: flex-start;

  &__left {
    flex-shrink: 0;
    margin-right: 66px;
    @include sm {
      display: none; } }

  &__main {
    max-width: 716px;
    margin-left: auto;
    display: flex;
    width: 100%;
    @include r(900) {
      flex-direction: column; } }


  &__check {
    min-width: 250px;
    max-width: 350px;
    flex-shrink: 0;
    margin-left: auto;
    @include r(900) {
      margin-top: 20px; }
    @include xs {
      display: none; } } }
