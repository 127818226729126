.pagination {
  $this: &;

  display: flex;
  align-items: center;

  &__item {
    &.is-prev,
    &.is-next {
      #{$this}__link {
        position: relative;
        top: 5px;
        margin: 0;
        font-size: 19px;
        @include xs {
          font-size: 15px; }
        &:before {
          display: none; } } }

    &.is-text {
      #{$this}__link {
        position: relative;
        top: 3px;
        margin: 0;
        width: 24px;
        &:before {
          display: none; } } } }

  &__link {
    position: relative;
    display: block;
    font-size: 15px;
    margin: 0 6px;
    background-color: transparent;
    width: 28px;
    height: 28px;
    opacity: 1;
    color: #000000;
    text-decoration: none;
    @include xs {
      height: 25px; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #DCDCDC;
      transition: background-color .15s ease-in-out; }

    &.is-active {
      &:before {
        background-color: $G-5; } }

    &:hover {
      &:before {
        background-color: #000000; } } } }
