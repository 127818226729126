$modal: '.modal';

.cart-popup {

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 48px;
    @include xs {
      padding: 18px 40px 18px 20px; } }

  &__title {
    font-size: 22px;
    @include semi;
    @include xs {
      font-size: 18px; } }

  &__total-sum {
    padding-right: 34px;
    font-size: 20px;
    @include semi;
    @include xs {
      display: none; } }

  &__empty-image {
    @include xs {
      margin-top: -14px;
      margin-bottom: 24px; }
    img {
      display: block;
      max-width: 100%; } }

  &__inner {
    position: relative;
    max-height: calc(100vh - 100px);
    width: 100%;
    padding: 20px 48px 30px;
    @include sm {
      padding: 16px 20px; }
    @include xs {
      max-height: none;
      padding-bottom: 0;
      padding-top: 2px;
      overflow: visible !important; }

    .ps__rail-x {
      display: none; }

    & > .ps__rail-y {
      z-index: 9;
      width: 4px;
      right: 24px !important;
      margin: 20px 0;
      border-radius: 2px;
      background-color: $G-3;
      @include sm {
        right: 8px !important; }
      @include xs {
        display: none; }

      .ps__thumb-y {
        border-radius: 2px;
        right: 0; } }

    .ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y {
      width: 4px; } }

  &__items {
    @include xsUp {
      .ps__rail-y,
      .ps__rail-x {
        display: none !important; } }

    @include xs {
      max-height: 340px;
      position: relative;
      width: calc(100% + 12px);
      padding-top: 12px;
      padding-right: 20px;
      margin-bottom: -1px;

      .ps__rail-x {
        display: none; }

      .ps__rail-y {
        width: 4px;
        border-radius: 2px;
        background-color: $G-3;
        margin: 12px 0;

        .ps__thumb-y {
          border-radius: 2px;
          right: 0; } }

      .ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y {
        width: 4px; } } }

  &__events-title {
    margin-top: 20px;
    font-size: 22px;
    color: #222222;
    @include semi;
    @include sm {
      display: none; } }

  &__related {
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    @include xs {
      display: none; }

    .products-tiles__title {
      font-size: 22px;
      @include semi; } }

  &__events {
    padding-bottom: 32px;
    @include sm {
      display: none; }

    &:not(:last-child) {
      border-bottom: 1px solid $G-3; } }

  &__main-actions {
    padding-top: 20px;
    padding-bottom: 20px;

    border-bottom: 1px solid $G-3;
    @include sm {
      border-top: 1px solid $G-3;
      border-bottom: none;
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
      background-color: #f7f7f7; } }

  #{$modal}__content {
    top: 0;
    width: 100%;
    max-width: 1000px;
    padding: 0;
    //padding: 24px 0 0
    transform: translate(-50%, 0);
    overflow: hidden;
    @include sm {
      padding: 0; }
    @include xs {
      transform: translate(0, 0);
      overflow-y: auto !important;
      padding-bottom: 0;
      border-radius: 0; } }

  #{$modal}__close {
    right: 25px;
    top: 24px;
    @include xs {
      right: 16px;
      top: 16px; }

    svg {
      width: 20px;
      height: 20px; } }


  &.is-faded {
    #{$modal}__content {
      overflow: hidden; } }

  .product-tile {
    &__info,
    &__action-btns {
      display: none; } } }
