.icon-10years {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-absorption {
  width: 1.1em;
  height: 1em;
  fill: none;
}
.icon-akzonobel {
  width: 1.44em;
  height: 1em;
  fill: initial;
}
.icon-alergic {
  width: 0.98em;
  height: 1em;
  fill: initial;
}
.icon-arrive {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-crop {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-arrow-down {
  width: 1.6em;
  height: 1em;
  fill: initial;
}
.icon-arrow-left {
  width: 0.63em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 0.63em;
  height: 1em;
  fill: initial;
}
.icon-atmosph {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-balance {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-beton-metal {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-bill {
  width: 0.78em;
  height: 1em;
  fill: initial;
}
.icon-biocides {
  width: 0.65em;
  height: 1em;
  fill: initial;
}
.icon-block {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-bluetooth {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-bottle-color {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-brash {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-breaks-water {
  width: 1.2em;
  height: 1em;
  fill: none;
}
.icon-burger {
  width: 1.83em;
  height: 1em;
  fill: initial;
}
.icon-bus {
  width: 1.56em;
  height: 1em;
  fill: initial;
}
.icon-cabinet {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-calc-pen {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-call-man {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-card-lock {
  width: 1.26em;
  height: 1em;
  fill: initial;
}
.icon-card {
  width: 1.28em;
  height: 1em;
  fill: initial;
}
.icon-cart {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-check {
  width: 1.29em;
  height: 1em;
  fill: initial;
}
.icon-chef-decor-1 {
  width: 0.8em;
  height: 1em;
  fill: initial;
}
.icon-chef-decor {
  width: 0.8em;
  height: 1em;
  fill: initial;
}
.icon-chef {
  width: 0.77em;
  height: 1em;
  fill: initial;
}
.icon-choice {
  width: 0.8em;
  height: 1em;
  fill: initial;
}
.icon-circle-crop {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-circles-check {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-clean {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-clock-from {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-clutch {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-coating {
  width: 0.89em;
  height: 1em;
  fill: initial;
}
.icon-coleration {
  width: 0.92em;
  height: 1em;
  fill: initial;
}
.icon-compare {
  width: 1.15em;
  height: 1em;
  fill: initial;
}
.icon-consult {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-copy {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-corrosion {
  width: 1.11em;
  height: 1em;
  fill: none;
}
.icon-courier {
  width: 0.78em;
  height: 1em;
  fill: initial;
}
.icon-cup {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
.icon-decor {
  width: 1.14em;
  height: 1em;
  fill: initial;
}
.icon-delete {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-delivery-1 {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-delivery-2 {
  width: 1.18em;
  height: 1em;
  fill: initial;
}
.icon-delivery-3 {
  width: 1.12em;
  height: 1em;
  fill: initial;
}
.icon-delivery-4 {
  width: 0.98em;
  height: 1em;
  fill: initial;
}
.icon-dilution {
  width: 0.71em;
  height: 1em;
  fill: #fff;
}
.icon-doc {
  width: 0.92em;
  height: 1em;
  fill: initial;
}
.icon-download {
  width: 0.75em;
  height: 1em;
  fill: initial;
}
.icon-dries-quickly {
  width: 0.97em;
  height: 1em;
  fill: #454545;
}
.icon-dust-free {
  width: 1.04em;
  height: 1em;
  fill: #3d4042;
}
.icon-eco {
  width: 0.98em;
  height: 1em;
  fill: initial;
}
.icon-ecolabel-second {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-ecolabel {
  width: 0.81em;
  height: 1em;
  fill: initial;
}
.icon-facebook-outline {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-factory {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fastdry-clock {
  width: 0.89em;
  height: 1em;
  fill: initial;
}
.icon-fastdry {
  width: 0.89em;
  height: 1em;
  fill: initial;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-filling {
  width: 2em;
  height: 1em;
  fill: none;
}
.icon-fire {
  width: 0.78em;
  height: 1em;
  fill: initial;
}
.icon-fits {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-flyer {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-for-rust {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-glass {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-guarantee {
  width: 0.89em;
  height: 1em;
  fill: initial;
}
.icon-hd-color {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-heart {
  width: 1.18em;
  height: 1em;
  fill: initial;
}
.icon-hides {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-highprice {
  width: 0.7em;
  height: 1em;
  fill: initial;
}
.icon-home-list {
  width: 1.16em;
  height: 1em;
  fill: initial;
}
.icon-home {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-house {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-icon-cart {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-info-bold {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-info-hover {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-info-warn {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-info {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-insta {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram-outline {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-leveled {
  width: 1.22em;
  height: 1em;
  fill: none;
}
.icon-light {
  width: 1.82em;
  height: 1em;
  fill: initial;
}
.icon-list-gear {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-lowprice {
  width: 0.7em;
  height: 1em;
  fill: initial;
}
.icon-maler {
  width: 0.81em;
  height: 1em;
  fill: initial;
}
.icon-matt {
  width: 0.79em;
  height: 1em;
  fill: initial;
}
.icon-matte {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
.icon-messenger {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-metal {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-moon {
  width: 0.87em;
  height: 1em;
  fill: initial;
}
.icon-no-priming {
  width: 1.33em;
  height: 1em;
  fill: none;
}
.icon-no-smell {
  width: 1.43em;
  height: 1em;
  fill: initial;
}
.icon-no-solvent {
  width: 0.65em;
  height: 1em;
  fill: initial;
}
.icon-no-voc {
  width: 1.04em;
  height: 1em;
  fill: none;
}
.icon-nohand {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-not-light {
  width: 1.22em;
  height: 1em;
  fill: #fff;
}
.icon-not-water {
  width: 0.76em;
  height: 1em;
  fill: none;
}
.icon-on-water {
  width: 0.79em;
  height: 1em;
  fill: none;
}
.icon-open {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-paint-cons {
  width: 0.83em;
  height: 1em;
  fill: none;
}
.icon-pantone-simple {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-pantone {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-papirus {
  width: 1.03em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-pin {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-porous {
  width: 1.04em;
  height: 1em;
  fill: none;
}
.icon-price {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-priming-painting {
  width: 0.84em;
  height: 1em;
  fill: none;
}
.icon-protect {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-protected {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
.icon-putty {
  width: 1.03em;
  height: 1em;
  fill: #fff;
}
.icon-quick {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
.icon-resist-damage {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-restoration {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-sale {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-sale_discount {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-sales {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-saves {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-search-reverse {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-security {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
.icon-seminar {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-share-fb {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-share-messenger {
  width: 0.95em;
  height: 1em;
  fill: initial;
}
.icon-share-skype {
  width: 0.95em;
  height: 1em;
  fill: initial;
}
.icon-share-telegram {
  width: 1.1em;
  height: 1em;
  fill: initial;
}
.icon-share-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-share-whatsapp {
  width: 0.95em;
  height: 1em;
  fill: initial;
}
.icon-share {
  width: 0.84em;
  height: 1em;
  fill: initial;
}
.icon-shlifarb-slider-arrow {
  width: 0.58em;
  height: 1em;
  fill: initial;
}
.icon-shrinkage {
  width: 2em;
  height: 1em;
  fill: none;
}
.icon-spraygun {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-stability {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
.icon-star-cake {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-subway {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-success {
  width: 1.17em;
  height: 1em;
  fill: initial;
}
.icon-sustainability {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-tel {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telegram-white {
  width: 1.05em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-texture {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-timer {
  width: 0.87em;
  height: 1em;
  fill: initial;
}
.icon-tint-amount {
  width: 1.7em;
  height: 1em;
  fill: initial;
}
.icon-tinting {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tm {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tmr {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tool-cleaning {
  width: 0.71em;
  height: 1em;
  fill: none;
}
.icon-user {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-van-solid {
  width: 1.43em;
  height: 1em;
  fill: initial;
}
.icon-van {
  width: 1.6em;
  height: 1em;
  fill: initial;
}
.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-wall-box {
  width: 1.16em;
  height: 1em;
  fill: initial;
}
.icon-wall {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-wallet {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-washing {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-wear-resist {
  width: 1.27em;
  height: 1em;
  fill: none;
}
.icon-weather {
  width: 0.97em;
  height: 1em;
  fill: none;
}
.icon-wide-arrow {
  width: 0.3em;
  height: 1em;
  fill: initial;
}
.icon-wood-pattern {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-wood-protection {
  width: 1.26em;
  height: 1em;
  fill: initial;
}
.icon-wood {
  width: 1.45em;
  height: 1em;
  fill: initial;
}
.icon-worker {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-xml {
  width: 1.09em;
  height: 1em;
  fill: initial;
}
.icon-youtube-outline {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-youtube {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
