.event {
  $this: &;

  display: flex;
  align-items: center;

  @include sm {
    flex-direction: column; }

  &__body {
    border: 1px solid #dcdcdc;
    padding-top: 62px;
    padding-bottom: 68px;
    min-height: calc(360px + 48px);

    @include md {
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: calc(280px + 48px); }

    @include sm {
      border: none;
      padding-top: 13px;
      padding-bottom: 0px;
      min-height: auto; } }

  &__image {
    display: block;
    min-width: 600px;
    width: 600px;
    height: 360px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    transform: translateX(0);
    transition: transform .25s ease-in-out;
    text-decoration: none;

    @include md {
      width: 450px;
      min-width: 450px;
      height: 280px; }

    @include r(850) {
      width: 350px;
      min-width: 350px;
      height: 240px; }

    @include sm {
      height: 0;
      padding-top: 60%;
      width: 100%;
      min-width: 100%; } }

  &__date {
    font-size: 12px;
    line-height: 1.34;
    color: $G-5;
    margin-bottom: 8px;

    @include sm {
      margin-bottom: 2px; } }

  &__status {
    display: inline-block;
    margin-bottom: 10px;
    padding: 6px 10px;
    background-color: #FFDD2D;
    text-transform: uppercase;
    color: #000000;
    font-size: 10px;
    @include bold;
    @include sm {
      position: absolute;
      bottom: 12px;
      left: 0;
      margin: 0; }
    &.is-ended {
      background-color: #DCDCDC;
      color: $G-5; }
    &.is-desktop {
      @include sm {
        display: none; } }
    &.is-mobile {
      display: none;
      @include sm {
        display: inline-block; } } }

  &__title {
    margin-bottom: 14px;
    text-decoration: none;
    display: inline-block;
    &:hover {
      span {
        background-image: none;
        color: #B94244; } }

    span {
      font-size: 28px;
      display: inline;
      color: $red;
      background-image: linear-gradient(#e3a1a1,#e3a1a1);
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 100% 1px;
      line-height: 1;

      @include sm {
        font-size: 22px; } }

    @include sm {
      margin-bottom: 11px; } }

  &__descr {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 16px;

    ul {
      li {
        position: relative;
        text-indent: 17px;

        &:before {
          content: '';
          position: absolute;
          top: 12px;
          left: 0;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          background-color: black; } } }

    @include sm {
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 6px; } }

  &--odd {
    &:hover {
      #{$this}__image {
        @include rmin(768) {
          transform: translateX(16px); } } }

    #{$this}__body {
      padding-left: 151px;
      margin-left: -91px;
      padding-right: 75px;

      @include md {
        padding-left: 125px;
        padding-right: 40px; }

      @include sm {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0; } } }



  &--even {
    flex-direction: row-reverse;
    &:hover {
      #{$this}__image {
        @include rmin(768) {
          transform: translateX(-16px); } } }

    #{$this}__body {
      margin-right: -91px;
      padding-left: 80px;
      padding-right: 151px;

      @include md {
        padding-right: 125px;
        padding-left: 40px; }

      @include sm {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0; } }

    @include sm {
      flex-direction: column; } }

  &--simple {
    align-items: flex-start;
    padding-top: 8px;
    @include xs {
      padding-bottom: 20px; }

    #{$this}__image {
      min-width: 468px;
      width: 100%;
      max-width: 468px;
      margin-right: 28px;
      height: 280px;
      @include md {
        min-width: 320px;
        max-width: 320px;
        height: 190px; }
      @include sm {
        max-width: 100%;
        min-width: auto;
        height: 0;
        max-height: 190px; } }

    #{$this}__body {
      border: none;
      padding: 0;
      min-height: auto;
      @include sm {
        padding-top: 12px; } }

    #{$this}__status {
      @include xs {
        position: absolute;
        bottom: 10px; } }

    #{$this}__date {
      margin-top: 10px; }

    #{$this}__title {
      margin-bottom: 8px;
      span {
        font-size: 20px;
        line-height: 1.4; } } }

  &--tile {
    display: block;
    width: 100%;
    max-width: 500px;
    margin-bottom: 60px;
    @include sm {
      max-width: none;
      margin-bottom: 40px; }

    &:not(:last-child) {
      margin-bottom: 60px;
      @include sm {
        margin-bottom: 40px; } }

    #{$this}__image-wrapper {
      position: relative;
      overflow: hidden; }

    #{$this}__image {
      min-width: auto;
      width: 100%;
      max-height: 300px;
      &:hover {
        transform: scale(1.05); } }

    #{$this}__body {
      padding: 0;
      margin: 0;
      border: none;
      min-height: auto; }

    #{$this}__status {
      position: absolute;
      bottom: 11px; }

    #{$this}__descr {
      margin-bottom: 14px;
      font-size: 16px;
      line-height: 1.7; }

    #{$this}__title {
      @include bold;
      margin-bottom: 10px;
      span {
        font-size: 22px;
        color: #000000;
        background: none;
        transition: color $easing-default;
        @include xs {
          font-size: 20px; }
        &:hover {
          color: $red; } } }

    .tag {
      color: $red;
      font-size: 11px;
      text-transform: uppercase;
      @include bold;
      margin: 12px 0;
      &:hover {
        text-decoration: underline; } } }

  &--related {
    #{$this}__image {
      max-height: 204px; } } }
