.diplomas {
  padding-top: 35px;
  padding-bottom: 23px;
  background-color: #F9F9F9;

  @include sm {
    padding-top: 23px;
    padding-bottom: 19px; }

  &__container {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px; }

  .swiper-container {
    padding: 20px 0 15px;

    @include xs {
      overflow: visible;
      padding-bottom: 0; } }

  &__title {
    margin-bottom: 22px;

    @include sm {
      text-align: left;
      margin-bottom: 13px; } }

  &__image {
    padding: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);

    img {
      width: 100%; }

    @include xs {
      padding: 8px 8px 3px; } }

  &__pagination {
    position: static;
    margin-top: 37px;

    @include xs {
      margin-top: 12px; }

    .swiper-pagination-bullet {
      position: relative;
      font-size: 15px;
      margin: 0 7px;
      background-color: transparent;
      width: 28px;
      height: 25px;
      opacity: 1;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #DCDCDC;
        transition: background-color .15s ease-in-out; }

      &.swiper-pagination-bullet-active {
        &:before {
          background-color: $G-5; } }

      &:hover {
        &:before {
          background-color: #000000; } }

      @include xs {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        color: transparent;
        border: 1px solid #888;
        background-color: transparent;
        margin: 0 4px;
        font-size: 0;

        &:before {
          display: none; }
        &.swiper-pagination-bullet-active {
          background-color: #888; } } } } }
