.section {
  $section: &;

  margin-bottom: 96px;
  @include sm {
    margin-bottom: 44px; }

  &--banner-slider {
    @include sm {
      margin-bottom: 12px; } }

  &--our-brands {
    @include sm {
      margin-bottom: 40px; }
    @include xs {
      margin-bottom: 0; } }

  &--product-main {
    margin-bottom: 29px;
    @include xs {
      margin-bottom: 10px; }
    &.has-border {
      margin-bottom: 12px;
      .product-main__inner {
        padding-bottom: 40px;
        border-bottom: 1px solid #dcdcdc; } } }

  &--product-info {
    margin-bottom: 22px; }

  &--related {
    position: relative;
    margin-bottom: 40px;
    z-index: 4; }

  &--viewed {
    position: relative;
    z-index: 3;
    margin-bottom: 40px; }

  &--text-bottom {
    margin-bottom: 54px;
    .content.is-expanded {
      .content__more {
        display: block; } }

    @include smUp {
      display: none; } }

  &:last-child {
    .products-tiles__slider:before {
      display: none; } }

  &--thanks-main {
    margin: 0; }

  &--our-services,
  &--our-advices {
    margin-bottom: 60px;
    @include xs {
      margin-bottom: 40px; } }

  &--blog-hero {
    margin-bottom: 0; }

  &--blog-events {
    margin-bottom: 60px;
    .events {
      padding-bottom: 0; } }

  &--blog-videos {
    margin-bottom: 60px;
    @include sm {
      margin-bottom: 55px; } }

  &--blog-socials {
    margin-bottom: 77px;
    @include sm {
      margin-bottom: 55px; } }

  &--related-articles {
    margin-bottom: 20px; }

  &--events {
    margin-bottom: 24px; }

  &--about-hero {
    margin-bottom: 40px;
    @include xs {
      margin: 0; } }

  &--our-brands-static {
    margin-top: 77px;
    @include xs {
      margin-bottom: 0; }
    .our-brands {
      margin-top: 30px; } }

  &--assortment {
    margin-top: 113px;
    margin-bottom: 52px;
    @include xs {
      margin-top: 0; } }

  &--main-advantages {
    margin-top: 110px;
    margin-bottom: 86px;
    @include xs {
      margin-top: 60px;
      margin-bottom: 60px; } }

  &--partners {
    margin-top: 96px;
    margin-bottom: 100px;
    @include xs {
      margin-top: 60px;
      margin-bottom: 60px; } }

  &--dealers-map {
    margin-bottom: 60px; }

  &--bottom-filters {
    margin-bottom: 88px;
    .filter-tabs {
      border-top: 1px solid $G-3;
      padding-top: 12px; } }

  &--contacts {
    margin-bottom: 78px;
    @include xs {
      margin-bottom: 60px; } }

  &--contact-person {
    margin-bottom: 105px;
    @include sm {
      margin-bottom: 24px; } }

  &--contact-slider {
    margin-bottom: 66px; }

  &--bank-details {
    margin-bottom: 77px;
    @include xs {
      margin-bottom: 60px; } }

  &--semi-advs {
    margin-bottom: 120px;
    @include sm {
      margin-bottom: 60px; } }

  &--registration {
    margin-top: 121px;
    @include sm {
      margin-top: 60px; } } }


.dropdown-rect {
  $this: &;

  background-color: white;
  border-radius: 4px;
  border: 1px solid #dcdcdc;

  &--gray {
    background-color: #F9F9F9; } }

.notification-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 295px;
  margin-left: -148px;
  margin-top: 19px;
  padding: 19px 24px 21px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease-in-out;
  &.is-open {
    opacity: 1;
    visibility: visible; } }

.favorite-dropdown {
  padding: 0 0 16px;

  &__button {
    max-width: 187px;
    margin: 19px 0 16px 24px; }

  &__list {
    max-height: 335px;
    position: relative;
    padding: 0 24px 21px;
    margin-top: 19px; }

  &__link {
    text-decoration: none;
    color: #000000;
    display: flex;
    &:hover {
      .removable-item__title {
        color: $red; } } } }

.mobile-dropdown {
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 111;

  @include rmin(1024) {
    display: none; }

  &__close {
    position: absolute;
    z-index: 3;
    top: 20px;
    right: 24px;
    background-color: transparent;

    .icon {
      font-size: 16px;
      fill: #666; } } }

.about-description + .section--dealers-map {
  margin-top: 80px; }
