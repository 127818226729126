.breadcrumbs {
  border-top: 1px solid #DCDCDC;
  background-color: #F7F7F7;

  @include sm {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 2px;
      width: 42px;
      z-index: 1;
      pointer-events: none;
      background-image: linear-gradient(180deg, #F7F7F7 21.59%, rgba(247, 247, 247, 0) 100%);
      transform: rotate(-90deg); } }

  &__container {
    display: flex;
    position: relative;

    @include sm {
      &.container {
        padding: 0;
        margin: 0 20px; } }

    span {
      &:first-child {
        .breadcrumbs__link,
        .breadcrumbs__item {
          padding-left: 0; } } }

    .ps__rail-x {
      height: 2px;
      background-color: #dcdcdc;
      border-radius: 0;
      @include sm {
        opacity: 1; }

      .ps__thumb-x {
        height: 2px;
        background-color: #888;
        bottom: 0;
        border-radius: 0; } } }

  &__item {
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.39;
    position: relative;
    padding-bottom: 2px;
    padding-left: 13px;
    padding-right: 21px;
    color: #888;
    white-space: nowrap; }

  &__last {
    padding-right: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }


  &__link {
    text-decoration: none;
    color: black;
    transition: color .2s ease;
    &:hover {
      color: $red; } }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    .icon {
      font-size: 44px;
      fill: #dcdcdc; } } }

