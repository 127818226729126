.u-bold {
  @include bold; }

.u-semi {
  @include semi; }

.u-extra-bold {
  @include extrabold; }

.u-light-color {
  color: #ffffff; }

.u-text-center {
  text-align: center; }

.u-sm-text-left {
  @include sm {
    text-align: left; } }

.u-md-text-left {
  @include md {
    text-align: left; } }

.u-no-mb {
  margin-bottom: 0; }

.u-no-mt {
  margin-top: 0; }

.u-no-ml {
  margin-left: 0; }

.u-no-mr {
  margin-right: 0; }


.u-no-border {
  border: 0 !important; }

.u-link-hover {
  transition: color .15s ease, border-color .15s ease;
  &:hover {
    color: $red;
    border-color: $red;
    span {
      border-color: $red; } } }

.u-default-link {
  display: inline;
  color: #000000;
  border-bottom: #000000;
  transition: color .15s ease, border-color .15s ease;

  &:hover {
    color: $red;
    border-color: $red; } }

.u-gray-link {
  display: inline;
  text-decoration: none;
  color: #000000;
  border-bottom: 1px solid rgba(68, 68, 68, 0.5);
  transition: color .15s ease, border-color .15s ease;

  &:hover {
    color: $red;
    border-color: $red; } }

.u-highlight {
  font-weight: bold;
  color: $red; }

.u-highlight-dark {
  font-weight: bold;
  color: #000000; }


.u-flex {
  display: flex; }

.u-flex-wrap {
  flex-wrap: wrap; }

.u-jsb {
  justify-content: space-between; }

.u-justify-center {
  justify-content: center; }

.u-aic {
  align-items: center; }

.is-desktop {
  @include xs {
    display: none; } }

.is-mobile {
  @include xsUp {
    display: none; } }

.is-tablet-desktop {
  @include sm {
    display: none; } }

.is-tablet {
  @include smUp {
    display: none; } }


.u-hidden {
  display: none; }

.u-visually-hidden {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  left: -99999px; }
