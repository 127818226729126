.cta-banner {
  $this: &;

  @include sm {
    margin: 0 -20px; }

  &__inner {
    min-height: 580px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    @include xs {
      min-height: 400px; } }

  &__content {
    width: 50%;
    padding-left: 26px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include md {
      margin: 0 auto; }

    @include sm {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center; } }

  &__title {
    line-height: 1.2;
    margin-bottom: 5px;

    a {
      display: inline-block;
      background-image: linear-gradient(#717477,#717477);
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 100% 1px;
      text-decoration: none;
      color: #000000;
      transition: color .15s ease;
      &:hover {
        color: $red;
        background-image: linear-gradient(rgba(198, 67, 68, 0.5),rgba(198, 67, 68, 0.5)); } } }

  &__subtitle {
    font-size: 15px;
    line-height: 1.34;
    margin-bottom: 18px;

    @include sm {
      margin-bottom: 15px; } }

  &__form {
    max-width: 320px;

    @include sm {
      .form__button .button {
        padding: 14px 16px;
        font-size: 16px; } }

    .tooltip {
      min-width: 180px; } }


  &__button {
    margin-top: 12px; }

  &__security {
    display: flex;
    color: $G-5;
    align-items: center;
    font-size: 11px;
    line-height: 2.19;
    margin-top: 9px;
    margin-left: 2px;

    .icon {
      font-size: 14px;
      fill: $G-5;
      margin-right: 8px; } }

  &__note {
    font-size: 13px;
    margin-top: 6px;
    line-height: 1.85;
    color: $G-5; }

  &__thanks {
    display: none; }

  &--primary {
    #{$this}__inner {
      position: relative;
      padding: 56px 0 30px;
      //background-image: url("../img/banners/shlifarb_cta-banner-tech.jpg")
      background-image: url("../img/banners/technologist_form_bg.jpg");
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../img/banners/technologist_form_pic.jpg");
        background-size: 50%;
        background-position: left center;
        background-repeat: no-repeat;
        pointer-events: none; } }
    @include md {
      #{$this}__inner {
        position: relative;
        z-index: 1;
        z-index: initial;
        background-image: url("../img/banners/shlifarb_cta-banner-tech-mob.jpg");
        background-position: center center;

        &:before {
          background-image: none;
 }          //background-color: rgba(225, 235, 243, 0.88)
        //  content: ''
        //  position: absolute
        //  top: 0
        //  left: 0
        //  right: 0
        //  bottom: 0
        //  z-index: -1
 } }        //  background-color: rgba(225, 235, 243, 0.88)
    @include sm {
      #{$this}__inner {
        padding: 26px 0 22px; } }
    @include xs {
      #{$this}__inner {
        z-index: initial;
        background-image: url("../img/banners/shlifarb_cta-banner-tech-mob.jpg");
        background-position: center center; } } }

  &--secondary {
    #{$this}__inner {
      position: relative;
      padding: 60px 0 47px;
      background-image: url("../img/banners/question_form_bg.jpg");
      min-height: 480px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../img/banners/question_form_pic.jpg");
        background-size: 50%;
        background-position: left center;
        background-repeat: no-repeat;
        pointer-events: none; }

      @include md {
        background-position: center bottom;
        background-image: url("../img/banners/shlifarb_cta-banner-call-mob.jpg");
        &:before {
          background-image: none; } }

      @include sm {
        padding: 25px 0 45px;
        min-height: 461px;
        background-position: center bottom;
        background-image: url("../img/banners/shlifarb_cta-banner-call-mob.jpg"); } }

    #{$this}__subtitle {
      @include sm {
        font-size: 14px;
        margin-bottom: 16px; } } }

  &--recommendation {
    #{$this}__title {
      @include xs {
        margin-bottom: 14px;
        font-size: 24px; } }

    #{$this}__content {
      width: 68%;
      @include md {
        margin-right: 0; }
      @include sm {
        margin: 0 auto;
        width: 100%; } }

    #{$this}__inner {
      padding: 44px 0 47px;
      background-image: url("../img/banners/shlifarb_cta-banner-recommendations.jpg");
      min-height: 363px;
      @include sm {
        min-height: 364px;
        padding: 47px 0 47px;
        background-position: center bottom;
        background-image: url("../img/banners/shlifarb_cta-banner-recommendations-mob.jpg"); } }

    #{$this}__subtitle {
      @include sm {
        font-size: 16px;
        margin-bottom: 16px; } } }

  &--buy-one-click {
    margin-top: 68px;
    margin-bottom: 60px;
    @include sm {
      margin-top: 35px;
      margin-bottom: 40px; }

    #{$this}__inner {
      display: flex;
      min-height: 381px;
      @include sm {
        display: block; } }

    #{$this}__content {
      width: 60%;
      padding-left: 110px;
      justify-content: flex-start;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-right: 0;
      @include md {
        padding-left: 50px; }
      @include sm {
        padding-left: 20px;
        width: 100%; } }

    #{$this}__left-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37%;
      @include sm {
        width: 100%;
        padding: 20px 50px 20px 20px; }
      img {
        @include sm {
          max-width: 90%; } } }

    #{$this}__title {
      margin-bottom: 21px;
      color: #ffffff; }

    #{$this}__security {
      color: #ffffff;

      svg {
        fill: #ffffff; } }

    .form__button {
      margin-top: 12px;

      .button {
        width: 150px;
        height: 51px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        background-color: #FFDA1B;
        border-radius: 30px;
        text-transform: uppercase; } }

    #{$this}__info {
      margin-top: 60px;
      margin-bottom: 60px;
      @include sm {
        margin-top: 40px;
        margin-bottom: 48px;
        max-width: 320px;
        width: 100%; } }
    #{$this}__thanks {
      padding-top: 56px;
      @include sm {
        padding-bottom: 98px;
        text-align: center; } }
    .thanks-msg__title {
      margin-top: 10px;
      margin-bottom: 16px;
      font-size: 30px;
      color: #ffffff;
      @include sm {
        font-size: 26px;
        line-height: 32px; } }
    .thanks-msg__text {
      font-size: 16px;
      color: #ffffff; } }


  @include xs {
    .tooltip {
      margin-top: 10px !important; }
    .field__tooltip-error .tooltip {
      max-width: 100%; }
    .field {
      .tooltip__arrow {
        transform: translate(20px,0)!important; }
      &.is-error {
        margin-bottom: 55px; } } } }
