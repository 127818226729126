.our-brands {
  $this: &;

  @include sm {
    margin-bottom: 0; }

  &__title {
    margin-bottom: 50px;

    @include sm {
      text-align: left;
      margin-bottom: 34px; } }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include md {
      padding-bottom: 30px; }
    @include sm {
      padding-bottom: 13px; } }


  &__link {
    text-decoration: none; }

  &__item {
    @include xs {
      margin-bottom: 40px; }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      @include xs {
        max-width: 92px; } } }

  &__logo {
    @include gray;
    transition: filter .25s ease-in-out;
    @include sm {
      @include gray-zero; }

    &:hover {
      @include gray-zero; } }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -10px;
    @include xs {
      bottom: 5px; } }


  .swiper-wrapper {
    &.is-disabled {
      justify-content: center;
      width: 100% !important; } }

  .swiper-wrapper {
    &:not(.is-disabled) {
      #{$this}__logo {
        @include xs {
          max-width: 88px; } }
      #{$this}__item {
        margin-bottom: 40px; }

      .swiper-slide {
        @include xs {
          max-width: 88px;
          margin-right: 12px; } } } } }
