.range-slider {
  padding-left: 20px;

  .noUi-handle {
    height: 20px;
    width: 20px;
    right: 0;
    border-radius: 50%;
    cursor: pointer; }

  .noUi-connects {
    background-color: #dcdcdc;
    left: -20px;

    width: calc(100% + 20px); }

  &.noUi-horizontal {
    height: 8px; }

  .noUi-connect {
    background-color: $error;
    border-radius: 3px; } }

.range-slider-value {
  font-size: 13px;
  color: black;
  text-align: center;

  &:after {
    content: attr(data-currency);
    font-size: 13px;
    color: black;
    margin-left: 5px; } }
