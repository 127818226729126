.main-field {
  width: 380px;

  @include xl {
    width: 100%; }

  &__control {
    height: 44px;
    border: 1px solid black;
    width: 100%;
    position: relative;
    @include sm {
      border: 0;
      border-bottom: 1px solid black; }


    input {
      font-size: 13px;
      height: 100%;
      width: 100%;
      padding: 5px 44px 5px 12px;
      @include osans;
      -webkit-appearance: none; // safari fix
      &::placeholder {
        color: #888; } } }

  &__button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-right: 12px;
    padding-left: 12px;
    &:hover {
      .icon {
        fill: $red; } }

    .icon {
      font-size: 20px;
      fill: black; } } }
