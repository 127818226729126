.nav-dropdown {
  $this: &;

  opacity: 0;
  visibility: hidden;
  transition: visibility .25s ease-out, opacity .25s ease-out;
  position: absolute;
  top: calc(100% + 5px);
  width: 232px;
  padding: 12px 0 20px;
  z-index: 2;

  &__item {
    position: relative;
    min-height: 24px;
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: background .25s ease-out;
    font-size: 13px;
    text-decoration: none;
    color: black;
    padding: 0 35px 0 20px;

    &:hover {
      background-color: #f1f1f1; }

    &--sub {
      cursor: pointer;

      .icon {
        transform: rotate(-90deg);
        margin-top: -2px;
        margin-left: auto;
        margin-right: 24px; }

      &:hover {
        .nav-dropdown {
          opacity: 1;
          visibility: visible; } } } }

  &__label {
    font-size: 13px;
    color: #999;
    @include bold;
    padding: 0 20px;
    line-height: 1.85; }

  &__group {
    position: relative;

    &:not(:last-child) {
      padding-bottom: 11px;
      margin-bottom: 7px;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #dcdcdc; } } }

  &--left {
    left: 0; }

  &--right {
    right: 0; }

  &--sub {
    top: -12px;
    left: 100%;
    z-index: 1;
    padding-bottom: 16px;
    width: auto;
    max-width: 230px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    padding-top: 11px;

    #{$this}__item {
      white-space: nowrap;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      line-height: 1;
      padding-top: 6px; } } }
