.faq {
  margin-bottom: 14px;
  @include xs {
    margin-bottom: 44px;
  }

  &__item {
    color: #000000;
    max-width: 665px;
    margin-left: auto;
    margin-right: 70px;
    border-bottom: 1px solid #dcdcdc;
    @include md {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.faq-title {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 38px;
  line-height: 1;
  color: #222222;
  @include sm {
    line-height: 1.2;
  }
  @include xs {
    margin-bottom: 3px;
    font-size: 22px;
  }

  span {
    color: #999999;
  }
}

.faq-question {
  position: relative;
  padding: 11px 18px 11px 36px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
  @include xs {
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 9px;
    top: 15px;

    border-right: 2px solid black;
    border-bottom: 2px solid black;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    transition: all .3s ease;
    @include xs {
      right: 0;
    }
  }

  &.is-active {
    &:after {
      top: 18px;
      transform: rotate(45deg) scale(-1);
    }
  }
}

.faq-answer {
  display: none;
  padding-left: 35px;
  padding-bottom: 28px;
  padding-right: 36px;
  font-size: 15px;
  line-height: 1.6;

  @include xs {
    padding-left: 0;
    margin-left: -3px;
    font-size: 13px;
  }

  a {
    color: $red;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }


    @include sm {
      max-width: 80%;
    }

    @include xs {
      max-width: 71%;
    }

    &:hover {
      border-color: transparent;
    }
  }

  p:first-child {
    margin-bottom: 5px;
  }

  p + p {
    margin-top: 8px;
  }

  &.is-active {
    display: block;
  }
}
