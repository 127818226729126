.search-results {
  &__head {
    padding: 14px 24px 13px;
    font-size: 14px;
    color: black;
    position: relative;
    @include sm {
      padding-left: 20px;
      padding-right: 20px; }

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      right: 24px;
      height: 1px;
      background-color: #e0e0e0;
      bottom: 0; } }

  &__link {
    font-size: 14px;
    color: black; }

  &__list {
    padding-left: 24px;
    padding-right: 24px;
    @include sm {
      padding-left: 20px;
      padding-right: 20px; } }

  &__item {
    padding-top: 12px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e0e0e0; }

  &__similars {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column; }

  &__similar {
    font-size: 15px;
    line-height: 1.34;
    text-decoration: none;
    //height: 32px
    position: relative;
    z-index: 1;
    color: black;
    display: inline-block;
    padding-bottom: 6px;
    padding-top: 6px;

    &:before {
      content: '';
      position: absolute;
      left: -24px;
      right: -24px;
      top: 0;
      bottom: 0;
      background-color: #f1f1f1;
      display: block;
      opacity: 0;
      z-index: -1;
      transition: opacity 200ms ease-out; }

    span {
      @include bold; }

    &:hover {
      &:before {
        opacity: 1; } } } }

.search-error {
  padding: 16px 24px 2px;
  font-size: 14px;
  line-height: 1.29; }
