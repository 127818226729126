.thanks-msg {

  &__icon {
    margin-bottom: 22px;
    font-size: 54px;
    color: #5EC355; }

  &__title {
    margin-bottom: 8px;
    font-size: 30px;
    color: #000000;
    @include bold;
    @include xs {
      font-size: 22px; } }

  &__text {
    line-height: 1.5;
    max-width: 350px;
    @include xs {
      font-size: 15px; } } }
