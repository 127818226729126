.work-process {
  padding-top: 23px;
  width: 100%;

  &__heading {
    margin-bottom: 79px;

    @include sm {
      font-size: 22px;
      line-height: 1;
      margin-bottom: 58px; } }

  &__list {
    position: relative;
    margin-bottom: -107px;

    @include md {
      margin-bottom: 0;
      padding-bottom: 170px; } } }
