.category-menu {
  $this: &;

  border: 1px solid #dcdcdc;
  margin-bottom: 32px;

  &__text {
    position: relative;
    font-size: 16px;
    color: black;
    line-height: 1.25;
    text-decoration: none;
    font-weight: 600;
    padding: 0 15px 0 30px;

    &:hover {
      color: $error; } }

  &__arrow {
    .icon {
      fill: black; } }

  &__reverse {
    &:not(:last-child) {
      #{$this}__text {
        border-bottom: 1px solid #dcdcdc; } }
    #{$this}__text {
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;

      #{$this}__arrow {
        position: absolute;
        top: 13px;
        left: 11px;
        transform: scale(1) translate(0,0);

        .icon {
          font-size: 6px; } }

      &.is-active {
        #{$this}__arrow {
          transform: rotate(-90deg) translate(-3px, -1px); } } } }

  &__item {
    &:not(.category-menu__reverse) {
      padding-top: 9px;
      &:last-child {
        padding-bottom: 25px; } } }


  &__submenu {
    padding-top: 3px;
    border-bottom: 1px solid #dcdcdc; }

  &__submenu-item {
    padding: 0 15px 0 14px;

    &:first-child {
      #{$this}__submenu-text:before {
        top: -3px; } } }

  &__submenu-text {
    font-size: 14px;
    line-height: 1.43;
    position: relative;
    padding-left: 17px;
    cursor: pointer;
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    z-index: 2;
    text-decoration: none;
    color: #000000;

    #{$this}__arrow {
      position: absolute;
      top: 4px;
      left: 0;

      .icon {
        font-size: 5px; } }

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: -14px;
      right: -15px;
      bottom: -3px;
      z-index: -1;
      background-color: #f7f7f7;
      opacity: 0; }

    &.is-active {
      font-weight: 600;
      color: $error;

      #{$this}__arrow {
        transform: rotate(-90deg) translate(-2px, -2px);
        .icon {
          fill: $error; } }

      &:before {
        opacity: 1; } }

    &:hover {
      color: $error;

      #{$this}__arrow {
        .icon {
          fill: $error; } } } }

  &__navi {
    position: relative;
    z-index: 1;
    display: none;
    padding-top: 5px;
    padding-bottom: 12px;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0px;
      right: -15px;
      background-color: #f7f7f7;
      left: -14px;
      bottom: 0; } }

  &__navi-item {
    padding-left: 34px;
    padding-right: 20px;

    &:not(:last-child) {
      margin-bottom: 10px; } }

  &__link {
    font-size: 15px;
    line-height: 1.34;
    color: black;
    text-decoration: none;

    &:hover {
      color: $error; } } }


