.our-service {
  $this: &;

  display: flex;

  &__icon {
    min-width: 30px;
    width: 30px;
    padding-top: 7px;

    .icon {
      fill: #B94244;
      font-size: 30px; } }

  &__heading {
    display: inline;
    font-size: 15px;
    color: $G-5;
    line-height: 1.34;
    font-weight: 600;

    &--attention {
      color: $red; } }

  &__link {
    background-image: linear-gradient(rgba(black, .5),rgba(black, .5));
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% 1px;
    text-decoration: none;
    color: $G-5;
    transition: color .15s ease;
    &:hover {
      color: $red;
      background-image: linear-gradient(rgba($red, .5),rgba($red, .5)); } }

  &__description {
    font-size: 13px;
    margin-top: 4px;
    line-height: 1.55; }

  &__body {
    padding-left: 8px; }

  &--support {
    #{$this}__icon {
      .icon {
        font-size: 30px; } } }

  &--tinting {
    #{$this}__icon {
      .icon {
        font-size: 26px; } } }

  &--delivery {
    #{$this}__icon {
      .icon {
        font-size: 18px; } } }

  &--payment {
    #{$this}__icon {
      .icon {
        font-size: 19px; } } }

  &--sale {
    #{$this}__icon {
      .icon {
        font-size: 26px; } } } }
