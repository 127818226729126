.reviews {

  &__title {
    margin-bottom: 12px;

    @include sm {
      text-align: left;
      margin-bottom: 0; } }

  &__container {
    position: relative; }

  &__slider {
    margin: 0 -28px;
    @include sm {
      padding-bottom: 27px;
      position: relative;
      margin: 0 -20px;

      &:before {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 20px;
        right: 20px;
        background-color: #dcdcdc; } } }

  &__slide {
    @include sm {
      max-width: 275px; } }

  &__arrows {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    @include sm {
      display: none; } }

  &__arrow {
    position: absolute;
    top: 130px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    cursor: pointer;
    transition: opacity .2s ease, transform .2s ease;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    background: #FFFFFF;
    &:hover {
      svg {
        fill: $red; } }

    svg {
      fill: $G-5;
      font-size: 24px; }

    &--prev {
      left: 20px;
      @include md {
        left: 28px; } }

    &--next {
      right: 20px;
      svg {
        transform: rotate(180deg); }

      @include md {
        right: 28px; } } }

  &__pagination {
    display: none;
    bottom: 5px !important;

    @include sm {
      display: block; } } }

