.user-notification {
  $this: &;

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    #{$this}__text {
      color: $red; } }

  &__icon {
    margin-right: 7px;
    margin-top: 9px;
    position: relative;

    > .icon {
      font-size: 18px; } }

  &__check {
    position: absolute;
    top: -4px;
    right: -4px;
    display: none; }

  &__text {
    font-size: 13px;
    line-height: 1.54; }

  &__count {
    font-size: 11px;
    line-height: 1.37;
    @include semi;
    margin-left: 5px;
    margin-top: 2px; }

  &__badge {
    position: absolute;
    top: -6px;
    right: -7px; }

  &--compare {
    #{$this}__icon {
      > .icon {
        font-size: 20px; } } }

  &--cart {
    #{$this}__icon {
      > .icon {
        font-size: 23px; } } }

  &--tiny {
    #{$this}__count {
      margin-left: 8px;
      margin-top: -5px; }

    #{$this}__icon {
      margin-top: 0;
      margin-right: 0; } }


  &.is-active {
    #{$this}__check {
      display: flex; } } }
