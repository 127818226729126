.mob-menu {
  $this: &;

  transform: translateX(-100%);
  transition: transform .3s ease-in-out;

  &.is-open {
    transform: translateX(0); }

  &__wrapper {
    padding: 24px 20px 31px; }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 12px; }


    &--gutter {
      padding-left: 58px; } }

  &__catalog {
    padding-bottom: 17px;
    border-bottom: 1px solid #E0E0E0; }

  // &__wrapper
  //   overflow: hidden

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    position: relative;

    &--button {
      cursor: pointer;
      z-index: 2; }

    &--home {
      #{$this}__icon {
        .icon {
          font-size: 18px; } } }

    &--heart {
      #{$this}__icon {
        .icon {
          font-size: 15px; } } }

    &--compare {
      #{$this}__icon {
        .icon {
          font-size: 17px; } } }

    &--cart {
      #{$this}__icon {
        top: 3px;
        left: -2px;
        .icon {
          font-size: 20px; } } }

    &--user {
      #{$this}__icon {
        .icon {
          font-size: 18px; } } }

    &--main {
      font-weight: 700; }

    &--arrow {
      #{$this}__arrow {
        margin-top: 2px;
        margin-left: 6px; } }

    &--catalog {
      padding-left: 38px; }

    &--sublink {
      #{$this}__arrow {
        margin-right: 8px;
        margin-top: 2px; }

      &.is-active {
        #{$this}__icon,
        #{$this}__arrow {
          .icon {
            fill: $error; } } } }


    &.is-active {
      font-weight: bold;

      #{$this}__arrow {
        transform: rotate(180deg); }

      &#{$this}__link--catalog {
        color: $error;
        font-weight: 400; }

      &#{$this}__link--main {
        font-weight: bold;
        color: black; } } }

  &__submenu {
    padding-top: 15px;
    background-color: #f1f1f1;
    margin-left: -22px;
    margin-right: -24px;
    padding-bottom: 24px;
    margin-bottom: 15px;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -50px;
      right: -50px;
      bottom: 0;
      z-index: -1;
      background-color: #f1f1f1; }

    &--catalog {
      #{$this}__icon {
        margin-right: 6px; }

      #{$this}__text {
        letter-spacing: .5px; } } }

  &__sub-level {
    padding-top: 21px;
    position: relative;
    margin-bottom: 31px;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 11px;
      left: -50px;
      right: -50px;
      bottom: -17px;
      z-index: -1;
      background-color: #fcfcfc;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc; } }

  &__submenu-last-level {
    padding-left: 58px;
    padding-top: 13px;
    margin-top: 12px;
    margin-bottom: 26px;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -17px;
      background-color: white;
      z-index: -1; } }

  &__badge {
    position: absolute;
    top: -8px;
    right: -6px; }

  &__text {
    font-size: 15px;
    line-height: 1.34; }

  &__icon {
    display: flex;
    margin-right: 16px;
    max-width: 22px;
    width: 22px;
    justify-content: center;
    position: relative;
    .check {
      position: absolute;
      right: -4px;
      bottom: -3px;
      .icon {
        font-size: 6px; } } }

  &__arrow {
    display: flex;
    .icon {
      font-size: 6px;
      fill: black;
      stroke-width: 2px; } }

  &__navs {
    padding-top: 16px;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 15px; }

  &__additional {
    padding-top: 12px; }

  &__heading {
    font-size: 13px;
    line-height: 1.54;
    color: #999;
    font-weight: bold;
    margin-bottom: 3px; }

  &__groups {
    position: relative;
    z-index: 1;
    padding-top: 8px;
    padding-left: 20px;
    margin-bottom: 26px;

    &:before {
      content: '';
      position: absolute;
      top: -24px;
      left: -24px;
      right: -24px;
      bottom: -17px;
      z-index: -1;
      background-color: #f1f1f1; } }

  &__group {
    &:not(:last-child) {
      margin-bottom: 26px; } }

  &__internal-sub {
    position: relative;
    z-index: 1;
    padding-top: 17px;
    padding-left: 20px;
    margin-bottom: 20px;

    &:before {
      content: '';
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      position: absolute;
      top: 7px;
      bottom: -13px;
      left: -50px;
      right: -50px;
      background-color: #fcfcfc; } }

  &__hide {
    display: none; } }
