.read-more {
  $this: &;

  display: flex;
  align-items: center;

  background-color: transparent;
  outline: none;
  color: #000000;
  font-size: 14px;
  @include hover {
    color: $red; }

  &__toggle {
    font-size: 13px;
    @include font-default;
    &.is-less {
      display: none; } }


  &__icon {
    font-size: 5px;
    margin-left: 5px;

    svg {
      display: block; } }

  &.is-dashed {
    #{$this}__toggle {
      border-bottom: 1px dashed rgba(68, 68, 68, 0.5); }
    @include hover {
      #{$this}__toggle {
        border-bottom: 1px dashed $red; } } }

  &.is-expanded {

    #{$this}__icon {
      transform: rotate(180deg); }
    #{$this}__toggle {
      &.is-less {
        display: block; }
      &.is-more {
        display: none; } } } }


