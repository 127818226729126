.f-nav {

  &__item {
    &:not(:last-child) {
      margin-bottom: 10px; } }

  &__link {
    color: white;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      color: $yellow; } } }
