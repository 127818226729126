.cart-proceed-link {
  $this: &;

  position: relative;
  display: inline-flex;
  text-decoration: none;
  color: #000000;

  &:hover {
    &:before {
      color: #D3E3F2; }

    #{$this}__inner {
      background-color: #D3E3F2; } }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: -15px;
    top: 0;
    display: inline-block;

    border-right: 16px solid;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    color: $blue-0; }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: -16px;
    top: 0;
    display: inline-block;

    border-right: 16px solid;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    color: $blue-1; }

  &__inner {
    position: relative;
    z-index: 3;
    padding: 9px 10px;
    background-color: $blue-0;
    border: 1px solid $blue-1;
    border-left: none; }

  &__text {
    display: block;
    max-width: 100px;
    font-size: 15px;
    line-height: 1.1; } }
