.rating {
  $this: &;
  display: flex;
  align-items: center;

  &__amount {
    display: flex;
    align-items: center; }

  &__item {
    color: #EFC900;
    &:not(:last-child) {
      margin-right: 3px; }

    &.is-rated {
      svg {
        fill: #EFC900; } } }

  &__reviews {
    margin-left: 9px;
    color: $G-5;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      color: $red; }

    &.is-underlined {
      color: $G-5;
      border-bottom: 1px dashed rgba(68, 68, 68, 0.5);
      line-height: 1.2;
      transition: color .15s ease, border-color .15s ease;

      &:hover {
        color: $red;
        border-color: $red; } }

    &-text {
      @include xs {
        display: none; } } }

  &__sum {
    margin-left: 2px;
    font-size: 12px;
    line-height: 1.1;
    color: #111111; }

  svg {
    fill: transparent;
    font-size: 13px; }

  &.is-simple {
    #{$this}__reviews {
      margin-bottom: -2px;
      margin-left: 14px;
      line-height: 1.1;
      border-bottom: 1px dashed rgba(68, 68, 68, 0.5); } } }
