.check {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &--primary {
    background-color: #C64344;
    padding-top: 1px;

    .icon {
      fill: white;
      font-size: 7px; } } }
