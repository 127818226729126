.social-link {
  $this: &;

  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  transition: background-color .2s ease;
  &:hover {
    .icon {
      fill: $G-0; }

    &.social-link--viber {
      background-color: $viber; }
    &.social-link--messenger {
      background-color: $messenger; }
    &.social-link--telegram {
      background-color: $telegram; } }

  &:not(:last-child) {
    margin-right: 16px; }

  .icon {
    transition: fill .2s ease; }

  &--viber {
    border-color: $viber;

    .icon {
      font-size: 20px;
      fill: $viber; } }

  &--messenger {
    border-color: $messenger;

    .icon {
      font-size: 18px;
      fill: $messenger; } }

  &--telegram {
    border-color: $telegram;

    .icon {
      margin-left: -2px;
      font-size: 18px;
      fill: $telegram; } } }
