.tooltip-trigger {
  display: flex;

  &:hover {
    .icon {
      fill: $error; } } }

.tooltip {
  $this: &;

  display: block;
  opacity: 0;
  visibility: hidden;
  //z-index: 999
  z-index: 2;
  background-color: #F9F9F9;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 14px 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  font-size: 13px;
  line-height: 1.54;
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;
  pointer-events: none;
  position: absolute; // fix before js init

  &[data-clickable="true"] {
    pointer-events: auto; }

  &:before {
    background-color: #F9F9F9;
    content: '';
    position: absolute;
    display: block;
    border-radius: 4px;
    z-index: 1; }

  @include xs {
    padding: 14px 16px;
    border-color: $G-5;
    border-width: 2px; }

  &__inner {
    display: block;
    transform: translateY(20px);
    transition: opacity .25s ease-in-out, transform .25s ease-in-out;
    position: relative;
    z-index: 1;
    @include xs {
      transform: translate(0, 0); } }

  &__image {
    display: block;
    margin-top: 6px; }

  &[data-show] {
    opacity: 1;
    visibility: visible;
    .tooltip__inner {
      transform: translateY(0); } }

  &__arrow {
    height: 18px;
    width: 18px; }

  &__arrow-inner {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #F9F9F9;
    border: 1px solid #E0E0E0;
    top: 0;
    left: calc(50% - 9px);
    transform: rotate(135deg);
    border-radius: 0 0 0 6px;
    @include xs {
      border-color: $G-5;
      border-width: 2px; } }

  &--small {
    min-width: 170px;
    max-width: 300px;
    padding: 6px 2px 7px;

    #{$this}__arrow {
      height: 10px;
      width: 10px; }

    #{$this}__arrow-inner {
      left: calc(50% - 5px); }

    &[data-popper-placement^='bottom'] {
      // & > .tooltip__arrow
 } }      //   top: -5px

  &--middle {
    padding: 5px 16px; }

  &--medium {
    padding: 8px 16px; }

  &--image {
    padding: 10px; } }


.tooltip[data-popper-placement^='top'] {
  & > .tooltip__arrow {
    top: calc(100% - 9px); }
  .tooltip__arrow-inner {
    transform: rotate(-45deg); }

  &:before {
    bottom: 0;
    left: 1px;
    right: 1px;
    height: 13px; } }

.tooltip[data-popper-placement^='top'].tooltip--small {
  & > .tooltip__arrow {
    top: calc(100% - 5px); } }

.tooltip[data-popper-placement^='bottom'] {
  > .tooltip__arrow {
    bottom: calc(100% - 9px); }

  &:before {
    top: 0;
    left: 1px;
    right: 1px;
    height: 13px; } }

.tooltip[data-popper-placement^='bottom'].tooltip--small {
  & > .tooltip__arrow {
    bottom: calc(100% - 5px); } }

.tooltip[data-popper-placement^='left'] {
  & > .tooltip__arrow {
    left: calc(100% - 9px); }
  .tooltip__arrow-inner {
    transform: rotate(-135deg); }

  &:before {
    top: 1px;
    right: 0;
    bottom: 1px;
    width: 13px; } }

.tooltip[data-popper-placement^='left'].tooltip--small {
  & > .tooltip__arrow {
    left: calc(100% - 5px); } }

.tooltip[data-popper-placement^='right'] {
  & > .tooltip__arrow {
    right: calc(100% - 9px); }
  .tooltip__arrow-inner {
    transform: rotate(45deg); }

  &:before {
    top: 1px;
    left: 0;
    bottom: 1px;
    width: 13px; } }

.tooltip[data-popper-placement^='right'].tooltip--small {
  & > .tooltip__arrow {
    right: calc(100% - 5px);
    margin-top: -3px; } }


