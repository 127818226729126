.loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.65);

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  &__spinner {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border: 4px solid transparent;
    border-top-color: $red;
    border-bottom-color: $red;
    animation: 1s spin linear infinite; } }
