.cart-popup-check {
  padding: 20px 28px;
  background-color: $G-1;
  border: 1px solid $G-3;

  &__discount {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
    color: $green-0;
    font-size: 13px;
    @include semi;
    line-height: 1.2; }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 13px;
    font-size: 18px;
    @include semi; }

  &__cost {
    margin-left: 20px;
    line-height: 1.2;
    @include bold;
    font-size: 22px;
    text-align: right;

    span {
      font-size: 28px; } }

  &__old-cost {
    margin-top: -12px;
    margin-bottom: 14px;
    font-size: 15px;
    text-align: right;
    text-decoration: line-through; }

  &__button {
    .button {
      font-size: 18px; } } }
