.amount-counter {
  $this: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 105px;
  padding: 12px 4px;
  border: 1px solid $G-5;

  &__increment,
  &__decrement {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    &:before {
      content: '';
      width: 12px;
      height: 2px;
      background-color: #DCDCDC; }

    &.is-active {
      &:before,
      &:after {
        background-color: $G-5; }

      &:hover {
        &:before,
        &:after {
          background-color: $red; } } } }

  &__increment {

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background-color: #DCDCDC;
      transform: translate(-50%, -50%) rotate(90deg); } }

  &__sum {
    width: 100%;
    font-size: 16px;
    color: #000000;
    text-align: center;
    background-color: transparent;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; } }

  &__label {
    padding: 0 4px;
    max-width: 100%;
    flex-grow: 1;
    text-align: center;

    input[type=number] {
      -moz-appearance: textfield; } }

  &__loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: $G-0;

    &.is-active {
      display: flex; } }

  &__spinner {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid transparent;
    border-top-color: $red;
    border-bottom-color: $red;
    animation: 1s spin linear infinite; }

  &--simple {
    height: 24px;
    padding: 4px;
    margin-top: 10px;
    border: 0; }

  &.is-disabled {
    opacity: 0.35;
    pointer-events: none;
    .amount-counter__increment {
      &:before,
      &:after {
        background-color: $G-3; } } } }
