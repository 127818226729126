.burger {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__lines {
    position: relative;
    width: 22px;
    height: 12px;
    margin-bottom: 5px;

    i, &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background-color: black;
      height: 2px; }

    &:before {
      top: 0; }

    &:after {
      bottom: 0; }

    i {
      top: 5px; } }

  &__text {
    text-transform: uppercase;
    font-size: 8px;
    line-height: 1.375;
    @include semi; } }
