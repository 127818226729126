.modal-relative {
  $this: &;

  display: none;
  position: absolute;
  z-index: 3;
  background: #ffffff;
  border: 1px solid #E0E0E0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  @include sm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 20px;
    z-index: 999; }

  &__content {
    position: relative;
    border-radius: 4px; }

  &__arrow {
    position: absolute;
    height: 18px;
    width: 18px; }

  &__arrow-inner {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #E0E0E0;
    top: 0;
    left: calc(50% - 10px);
    transform: rotate(135deg);
    border-radius: 0 0 0 6px;
    @include xs {
      border-color: $G-5;
      border-width: 2px; } }

  &__close {
    position: absolute;
    background-color: transparent;
    font-size: 20px;
    color: #666666; }

  &--left {
    top: 50%;
    right: calc(100% + 20px);
    transform: translate(0, -50%);
    @include md {
      top: calc(100% + 20px);
      right: auto;
      left: 50%;
      transform: translate(-50%, 0); }
    @include sm {
      top: 0;
      left: 0;
      transform: translate(0, 0); }

    #{$this}__arrow {
      left: calc(100% - 9px);
      top: 50%;
      transform: translate(0, -50%);
      @include md {
        top: -9px;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0); }
      @include sm {
        display: none; } }


    #{$this}__arrow-inner {
      border-radius: 0 6px 0 0;
      transform: rotate(45deg);
      @include md {
        border-radius: 6px 0 0 0; } } } }
