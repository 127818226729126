.events {
  position: relative;
  padding-bottom: 78px;
  @include sm {
    padding-bottom: 28px; }

  &__title {
    margin-bottom: 31px; }

  &__heading {
    margin-bottom: 31px;
    @include sm {
      margin-bottom: 22px; } }

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
    height: 100%; }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    cursor: pointer;
    transition: opacity .2s ease,transform .2s ease;
    &--prev {
      left: -53px; }
    &:hover {
      transform: translateX(-8px); }
    &--next {
      right: -53px;
      transform: scale(-1);
      &:hover {
        transform: translateX(8px) scale(-1); } }
    &.is-hidden {
      display: none; }
    svg {
      font-size: 24px;
      fill: $G-5; } }

  &__list {
    margin-bottom: 40px;
    @include sm {
      margin-bottom: 29px; }

    &--tiles {
      display: flex;
      flex-wrap: wrap;
      max-width: 1040px;
      margin: 31px auto 0;
      justify-content: space-between;
      @include xs {
        margin: 32px auto 0; }

      .event {
        width: calc(50% - 20px);
        @include sm {
          width: 100%; }
        &:nth-child(odd) {
          margin-right: 40px;
          @include sm {
            margin: 0 0 40px; } } } }

    &--related {
      display: flex;
      flex-wrap: wrap;
      max-width: 1110px;
      margin: 31px auto 0;
      justify-content: center;
      @include xs {
        margin: 32px auto 0; }

      .event {
        max-width: 340px;
        width: calc(100%/3 - 30px);
        @include md {
          width: calc(100%/2 - 30px); }
        @include xs {
          width: 100%; }
        &:not(:last-child) {
          margin-right: 30px;
          @include xs {
            margin-right: 0; } }

        @include sm {
          width: 100%;
          margin: 0 0 40px; } } } }

  &__item {
    &:not(:last-child) {
      margin-bottom: 64px;
      @include sm {
        margin-bottom: 48px; } } }

  &__pagination {
    margin-top: 20px;
    left: 50%;
    &.swiper-pagination {
      transform: translateX(-50%); } }

  &__button {
    width: 100%; }

  &--slider {
    @include sm {
      padding-bottom: 64px; }
    .common-pagination {
      @include xs {
        left: 50%;
        transform: translateX(-50%); } } } }
