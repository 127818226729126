.common-description {
  margin: 0 auto;
  font-size: 24px;
  line-height: 1.83;
  color: #222222;
  text-align: center;
  font-weight: normal;
  @include sm {
    font-size: 18px;
    line-height: 1.56; }

  &--sm {
    max-width: 540px; } }
