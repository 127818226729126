.title {
  color: #000000;
  @include bold;

  $this: &;

  &--primary {
    font-size: 36px;
    line-height: 1.23;

    @include sm {
      font-size: 22px;
      line-height: 1.37; } }

  &--basic {
    font-size: 32px;
    line-height: 1; }

  &--common {
    font-size: 32px;
    line-height: 1;
    font-weight: normal;
    @include xs {
      font-size: 24px; } }

  &--secondary {
    font-size: 30px;
    line-height: 1.07;

    @include sm {
      font-size: 22px;
      line-height: 1.09; } }

  &--tertiary {
    font-size: 28px;
    line-height: 1.36;

    @include sm {
      font-size: 22px; } }

  &--center {
    text-align: center; }

  &--light {
    color: #ffffff;

    #{$this}--link {
      background-color: #ffffff; } }

  &--sub-black {
    color: #222; }

  &--link {
    a {
      position: relative;
      display: inline-block;
      color: inherit;
      text-decoration: none;
      transition: color .2s ease-out;

      &:before {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(black, .5);
        transition: background .2s ease-out; }

      &:hover {
        color: $red;

        &:before {
          background-color: $red; } } } } }


.static-title {
  padding: 0 10px;
  font-size: 36px;
  line-height: 0.9;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  @include bold;
  @include sm {
    font-size: 28px;
    line-height: 1.14; } }

.static-subtitle {
  padding: 0 10px;
  margin-top: 14px;
  font-size: 18px;
  line-height: 0.9;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  @include sm {
    margin-top: 6px;
    font-size: 16px;
    line-height: 1.14; } }
