.removable-item {
  $this: &;

  position: relative;
  display: flex;
  padding-right: 30px;

  &__image {
    max-width: 40px;
    min-width: 40px;
    min-height: 40px;
    max-height: 40px;
    margin-right: 14px; }

  &__title {
    font-size: 14px;
    line-height: 1.29;
    text-decoration: none;
    color: #000000;
    &:hover {
      color: $red; }

    span {
      margin-left: 2px;
      font-size: 13px;
      @include semi; } }

  &__close {
    position: absolute;
    top: 11px;
    right: 0;
    z-index: 3;
    background-color: transparent;

    .icon {
      font-size: 10px; } }

  &:first-child {
    #{$this}__close {
      top: 3px; } }

  &--center {
    #{$this}__close {
      top: 50%;
      margin-top: -5px; }

    &:first-child {
      #{$this}__close {
        margin-top: 8px; } }

    &:last-child {
      #{$this}__close {
        margin-top: 1px; } } }

  &:not(:last-child) {
    padding-bottom: 11px;
    border-bottom: 1px solid #dcdcdc; }

  &:not(:first-child) {
    padding-top: 13px; } }
