.content {
  $this: &;

  color: $G-5;

  &__container {
    max-width: 880px;
    padding: 0 20px;
    margin: 0 auto; }

  p {
    font-size: 12px;
    line-height: 1.67;
    margin-bottom: 8px;

    strong {
      @include semi; } }

  ul {
    li {
      position: relative;
      margin-bottom: 8px;
      text-indent: 11px;

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        display: inline-block;
        border-radius: 50%;
        background-color: $G-5;
        max-width: 3px;
        min-width: 3px;
        min-height: 3px;
        max-height: 3px; }


      p {
        margin-bottom: 0; } } }

  &__toggle {
    background-color: transparent;
    display: none;
    font-size: 12px;
    align-items: center;
    line-height: 1;
    position: relative;
    margin-top: 12px;

    .icon {
      font-size: 5px;
      fill: $G-5;
      position: absolute;
      left: calc(100% + 5px); }

    @include sm {
      display: flex; } }

  &__more {
    @include sm {
      display: none; } }

  &__toggle-more {
    color: $G-5;
    border-bottom: 1px solid rgba(68, 68, 68, 0.5);
    &:hover {
      color: $red;
      border-color: $border-hover-color; } }

  &__toggle-less {
    display: none;
    color: $G-5;
    border-bottom: 1px solid rgba(68, 68, 68, 0.5);
    &:hover {
      color: $red;
      border-color: $border-hover-color; } }


  &.is-expanded {
    #{$this}__toggle-less {
      display: block; }

    #{$this}__toggle-more {
      display: none; }

    #{$this}__toggle {
      .icon {
        transform: rotate(180deg); } } }




  &--toggle {
    @include sm {
      position: relative; } } }
