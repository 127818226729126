.nav {
  display: flex;

  &__list {
    display: flex;
    padding-right: 27px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      right: 0;
      height: 16px;
      width: 1px;
      background-color: #dcdcdc; } }

  &__item {
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 22px;

      @include xl {
        margin-right: 12px; } }

    &--dropdown {
      padding-right: 13px;


      .icon {
        font-size: 5px;
        position: absolute;
        top: 50%;
        right: 0; }

      &:hover {
        > .nav-dropdown {
          opacity: 1;
          visibility: visible; } } } }

  &__link {
    font-size: 13px;
    color: black;
    text-decoration: none;
    &:hover {
      color: $red; } }

  &__lang {
    font-size: 13px;
    padding-left: 21px;
    padding-top: 3px;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    &:hover {
      color: $red; } } }
