.cart-popup-item {
  $this: &;

  position: relative;
  display: flex;
  margin-top: 20px;
  &:first-child {
    margin: 0; }
  @include xs {
    border-bottom: 1px solid $G-3; }

  &:first-child {
    @include xs {
      margin-top: 0; } }

  &__main {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid $G-3;
    @include xs {
      border: none;
      padding-bottom: 20px; } }

  &__image {
    max-width: 160px;
    margin-right: 28px;
    flex-shrink: 0;
    @include sm {
      max-width: 80px; }
    @include xs {
      margin-right: 14px; }

    img {
      max-width: 100%; } }

  &__color {
    max-width: 500px;
    margin-top: 6px;
    display: inline-block;
    font-size: 13px;
    @include semi; }

  &__info {
    @include xs {
      display: none; } }

  &__content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    @include xs {
      margin-top: 8px; } }

  &__title {
    font-size: 16px;
    font-weight: normal;
    @include xs {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      min-height: 40px; } }

  &__actions {
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    @include xs {
      justify-content: space-between;
      width: 100%;
      margin-left: 0;
      flex-wrap: wrap; } }

  &__action-col {
    margin-left: 38px;
    @include xs {
      position: relative;
      margin-left: 0; }

    &--mob-right {
      @include xs {
        margin-left: auto; } }

    &--cost {
      min-width: 115px;
      @include xs {
        min-width: auto; } } }

  &__cost {
    margin-top: 16px;
    @include semi;
    font-size: 18px;
    @include xs {
      font-size: 16px;
      white-space: nowrap;
      text-align: right; }

    span {
      margin-right: 4px;
      font-size: 24px;
      @include xs {
        font-size: 22px; } }

    &.is-discount {
      color: $red;
      span:first-child {
        font-weight: bold;
        margin-right: 4px;
        font-size: 20px; }
      span:last-child {
        color: #000000;
        margin-left: 8px; } } }

  &__old-cost {
    margin-right: 1px;
    font-size: 14px;
    text-decoration: line-through;
    @include xs {
      text-align: right;
      font-size: 13px; } }

  &__cost-info-main {
    font-size: 18px;
    @include semi;
    &.is-discount {
      span:first-child {
        color: $red;
        font-weight: bold;
        margin-right: 4px;
        font-size: 20px; } } }

  &__action-delete {
    text-align: center;
    @include xs {
      position: absolute;
      left: -100%;
      bottom: -2px;
      transform: translateX(25px); }

    span {
      display: inline-block;
      line-height: 1.1;
      font-size: 13px;
      border-bottom: $border-dashed-gray;
      cursor: pointer; } }

  &__note {
    margin-bottom: 6px;
    font-size: 13px;
    color: $G-4;
    @include xs {
      display: none; } }


  &__actions-popup {
    position: absolute;
    right: 114px;
    bottom: 14px;
    z-index: 3;

    display: flex;
    justify-content: center;
    width: 308px;
    height: 124px;

    background-color: $G-0;
    border: 1px solid $G-3;
    border-radius: 2px;

    opacity: 0;
    pointer-events: none;
    @include xs {
      right: 0; }
    @include r(350) {
      right: -5px;
      width: 270px; }

    &.is-active {
      opacity: 1;
      pointer-events: auto; }

    &:before {
      content: '';
      position: absolute;
      left: 54%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 100px;
      background-color: $G-3; } }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 11px;
    line-height: 1;
    color: #ffffff;
    @include bold;
    font-size: 13px;
    border-radius: 14px;
    z-index: 9;
    @include sm {
      padding: 4px 7px 5px;
      font-size: 10px; }

    &.is-discount {
      background-color: #D01000; }

    &.is-new {
      background-color: #31945E; } }

  &__control {
    width: 50%;
    flex-shrink: 0;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    @include r(350) {
      padding: 12px 9px; }

    &:first-child {
      width: 165px;
      padding-left: 25px;
      @include r(350) {
        width: 150px;
        padding-left: 15px; } }

    &:hover {
      span {
        color: $red; }

      .icon {
        fill: $red; } }

    span {
      font-size: 15px;
      color: #000000;

      @include r(350) {
        font-size: 14px; } }

    .icon {
      display: block;
      margin: 0 auto 10px;

      &-heart {
        font-size: 26px; }

      &-delete {
        font-size: 24px;
        fill: $red; } } }

  .amount-counter {
    @include xs {
      height: 46px; } } }
